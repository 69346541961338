import './styles.scss';
import React, {useContext, useEffect, useState} from "react";

/* Type, Constants, Utils */
import Utils from "../../../../utils";

import {IRecordsContext, RecordsContext} from "../../../../types/document";
import * as Constants from "../../../../constants";
import {IUserState} from "../../../../types/redux/user";

/* Bootstrap */
import {Dropdown} from "react-bootstrap";

/* Redux */
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux";

/* Components */
import DownloadModal from "../../../modal/download-modal";
import LoginPromptModal from "../../../modal/login-prompt-modal";

/* Hooks */
import useDownloadFile from "./useDownloadFile";

export interface IDownload {
  filename: string,
  percentage: number
}
const ALLOWED_MEDIA: string[] = ['text','image','pdf','zip'];

const DownloadDropdown = ():JSX.Element => {
  /* context */
  const record: any = useContext<IRecordsContext>(RecordsContext).records[0];

  /* redux */
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);

  /* state */
  const [pendingLogin, setPendingLogin] = useState<boolean>(false);
  const [loginPromptModal, setLoginPromptModal] = useState<boolean>(false);
  const artifacts: any = record?.artifact ? JSON.parse(record.artifact) :  null ;
  let allFilesTotalBytes: number = 0;

  /* hooks */
  const {downloadFile, downloads} = useDownloadFile();

  useEffect(()=>{
    if (pendingLogin && userState.loggedIn) {
      setPendingLogin(false);
      downloadFile(record.id, `${record.id}.zip`, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.loggedIn])

  const downloadMenuItem = (key: string, name: string, size: string):JSX.Element => {
    return (
      <Dropdown.Item
        key={key}
        onClick={() => {
          if (name === Constants.ALL_FILES_ZIP) {
            if (userState.loggedIn) {
              downloadFile(record.id, `${record.id}.zip`, true)
            } else {
              setPendingLogin(true);
              setLoginPromptModal(true);
            }
          } else {
            downloadFile(record.id, name, false)
          }
        }}
      >
        <i className={'bi bi-download fst-normal me-1 bg-transparent'}/>
        <span>{name} - {Utils.convertBytes(size)}</span>
      </Dropdown.Item>
    )
  }

  return (
    <>
      <Dropdown className={'download-dropdown'}>
      <Dropdown.Toggle
        disabled={!record || !artifacts}
        variant={"success"}
        id={"dropdown-download"}
        className={"btn btn-sm btn-light mt-1"}
      >
        <i className={'bi bi-download'}/>
        <span className={'ms-2 me-1'}>Download</span>
      </Dropdown.Toggle>

      {artifacts && <Dropdown.Menu>
        {artifacts.map((artifact:any, index:number) => {
          /* disallow video and audio files from downloads */
          const mediaType: string[] = artifact.mediaType.split('/');
          if (ALLOWED_MEDIA.indexOf(mediaType[0]) || ALLOWED_MEDIA.indexOf(mediaType[1])) {
            allFilesTotalBytes += artifact.size;
            return downloadMenuItem(`dlmi${index}`, artifact.name, artifact.size)
          }
          return <></>
        })}

        {downloadMenuItem(`dlmi${artifacts.length+1}`,
          Constants.ALL_FILES_ZIP,
          String(allFilesTotalBytes)
        )}
      </Dropdown.Menu>}
    </Dropdown>

      <DownloadModal
        downloads={downloads}
        show={downloads.length > 0}
      />

      {loginPromptModal && <LoginPromptModal
        title={'Download All Files'}
        show={loginPromptModal}
        onHide={()=>setLoginPromptModal(false)}/>}
    </>
  )
}

export default DownloadDropdown;
