/* Type, Constants, Utils */
import * as Constants from "../constants";

/* Redux */
import {useDispatch} from "react-redux";

import {searchReset, setDBSet, setFiltersKey} from "../redux/slices/search-slice";
import {clearFacets, clearResults, clearSelectedResults} from "../redux/slices/result-slice";
import {Dispatch} from "react";
import {AnyAction} from "@reduxjs/toolkit";

const useSearchReset = (): Function => {
  const dispatch:Dispatch<AnyAction> = useDispatch();

  return (dbSet:string): void => {
    dispatch(setDBSet(dbSet)); // filter reset may be dependent on if content page or not
    dispatch(searchReset())
    dispatch(clearResults());
    dispatch(clearFacets());
    dispatch(clearSelectedResults());
    dispatch(setDBSet(dbSet)); // search resets dbSet to default so have to reset it
    dispatch(setFiltersKey(dbSet ? dbSet : Constants.DOCUMENTS))
  }
};

export default useSearchReset;
