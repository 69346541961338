import './styles.scss';
import React, {Dispatch, SyntheticEvent, useEffect, useRef, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../constants";
import {PageContext} from "../../types/app";
import Utils from "../../utils";

/* Bootstrap */
import {Button, Col, Container, Nav, Navbar, Row} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {addCrumb} from "../../redux/slices/breadcrumb-slice";

/* Components */
import ViewScrollContainer from "../../components/view/view-scroll-container";

import {setLoginModalActive} from "../../redux/slices/login-modal-slice";
import {IUserState} from "../../types/redux/user";
import SettingsMyAccount from "./components/my-account";
import SettingsGeneral from "./components/general";

const Settings = ():JSX.Element => {

  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);

  /* state */
  const [activeKey, setActiveKey] = useState<string | null>(null);

  /* refs */
  const activeRef = useRef<HTMLInputElement | null>(null);

  /* todo: jain - handle select on resize */

  useEffect(()=> {
    dispatch(addCrumb({
      label: Utils.toTitleCase(Constants.SETTINGS),
      href: '/' + Constants.SETTINGS,
      level: 2,
      active: true
    }))

    if (activeRef.current) {
      activeRef.current.style.top = '8px';
    }

    setActiveKey(Constants.GENERAL_SETTINGS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.loggedIn]);

  const handleSelectMenu = (key: string | null, e:SyntheticEvent<unknown, Event>): void => {
    setActiveKey(key);

    const target: any = e.currentTarget;
    if (activeRef.current) {
      activeRef.current.style.top = target.offsetTop  + 'px';
    }
  }

  return (
    <div className={'settings-view view'}>
      <PageContext.Provider value={{context: Constants.SETTINGS}}>

        <ViewScrollContainer>
          <Container className={"page-container my-1 my-lg-5"}>
            <Row>
              <Col lg={2}>
                <Navbar expand="lg" className="bg-body-tertiary"
                        onSelect={(eventKey:string | null, e: SyntheticEvent<unknown, Event>)=>handleSelectMenu(eventKey, e)}>

                  <Container className={'p-0'}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className={'bg-white border-grey'}/>
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav defaultActiveKey={Constants.GENERAL_SETTINGS} className={'me-auto'}>
                        <Col>
                          <Nav.Link eventKey={Constants.GENERAL_SETTINGS} href={"#" + Constants.GENERAL_SETTINGS}>General&nbsp;Settings</Nav.Link>
                          <Nav.Link eventKey={Constants.MY_ACCOUNT} href={"#" + Constants.MY_ACCOUNT}>My Account</Nav.Link>
                        </Col>
                      </Nav>
                      <div ref={activeRef} className={'active_select'}/>
                    </Navbar.Collapse>
                  </Container>

                </Navbar>
              </Col>

              <Col className={'ms-lg-2'}>
                {(activeKey === Constants.GENERAL_SETTINGS) &&
                  <Col>
                    <SettingsGeneral/>
                  </Col>}

                {(activeKey === Constants.MY_ACCOUNT) &&
                  !userState.loggedIn &&
                    <div className={'login-notify text-center pb-5'}>
                      <h5 className={'pb-2'} >You must be logged in to access your settings.</h5>
                      <Button variant="primary" onClick={()=>dispatch(setLoginModalActive(true))}>Log In or Register</Button>
                    </div>}

                {(activeKey === Constants.MY_ACCOUNT) &&
                  userState.loggedIn &&
                    <Col lg={10}>
                      <SettingsMyAccount/>
                    </Col>}
              </Col>

            </Row>
          </Container>
        </ViewScrollContainer>
      </PageContext.Provider>
    </div>
  )
}

export default Settings;
