import './assets/styles/app.scss';
import React, {Dispatch, useContext, useEffect, useRef} from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";

/* types, constants, utils */
import * as Constants from "./constants";
import {ITagState} from "./types/redux/saved";
import {IUserState} from "./types/redux/user";
import {IViewerState} from "./types/redux/viewer";

/* redux */
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./redux";
import {AnyAction} from "@reduxjs/toolkit";
import {ISearchState} from "./types/redux/search";
import { setLoginModalActive} from "./redux/slices/login-modal-slice";
import {ILoginModal} from "./types/redux/login-modal";

/* toast */
import {removeToast} from "./redux/slices/toast-slice";
import {Toast, ToastContainer} from "react-bootstrap";
import {IToast, IToasts} from "./types/redux/toast";

// routes
import {
  Home,
  MyLibrary,
  Industries,
  Settings,
  Content,
  Login,
  Viewer,
  ResetPassword
} from "./views";

// components
import HeaderUCSF from "./components/header-footer/header-ucsf";
import HeaderIDL from "./components/header-footer/header-idl";
import BreadCrumb from "./components/header-footer/breadcrumb";
import ScrollToTop from "./components/util/scroll-to-top";

import NoPathMatch from "./components/util/no-path-match";
import LoginModal from "./components/modal/login-modal";
import TagsNotesService from "./api/tags-notes";
import {setLoadedTags} from "./redux/slices/tags-notes-slice";
import HeaderFeedback from "./components/header-footer/header-feedback";
import Preview from "./views/content/components/preview";
import NotificationService from "./api/notifications";
import {setLoggedIn, setNotifications} from "./redux/slices/user-slice";
import {HistoryContext} from "./components/util/history";

const App = (): JSX.Element =>  {
  /* redux  */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);

  const loginModalState: ILoginModal = useSelector<RootState, ILoginModal>(state => state.login_modal);
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);
  const tagsState: ITagState = useSelector<RootState, ITagState>(state => state.tags);
  const searchState: ISearchState = useSelector<RootState, ISearchState>((state: RootState):any => state.search);
  const toastsState: IToasts = useSelector<RootState, IToasts>((state: RootState):any => state.toasts);

  /* refs */
  const appRef = useRef(null);
  const { setHistory, history } = useContext(HistoryContext);

  useEffect(()=>{
    const _history: string[] = [...history];
    // Top level Content route not being recognized unless useLocation is implored - see Content component
    if (window.location.pathname !== _history[_history.length - 1]) {
      _history.push(window.location.pathname);
      setHistory(_history)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  useEffect(()=> {
    if (userState.loggedIn && !tagsState.loaded) {
      /* load all tags */
      TagsNotesService.getAllTags().then(response=>{
        if (!Array.isArray(response)) {
          response = []; // backend return empty string if there are not any tags
        }
        dispatch(setLoadedTags(response));

        /* user verified logged - load all notifications */
        if (!userState.notificationsLoaded) {
          NotificationService.getAllNotification().then(response=>{
            dispatch(setNotifications(response.notificationRequest));
          }).catch(e => {
            console.error('Unable to retrieve notifications ' + e)
          })
        }

      }).catch(e => {
        if ((e.status === 403) || (e.response && ((e.response.status === 403) || (e.response.status === 401)))) {
          /* error case - login status out of sync with backend */
          dispatch(setLoggedIn(false))
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsState.loaded, userState.loggedIn]);

  const toastELem = (toastData: IToast): JSX.Element => {
    const msgArr: string[] = toastData.msg.split('\n')
    return (
      <Toast
        className={toastData.bg}
        key = {toastData.id}
        onClose={()=>dispatch(removeToast(toastData.id))}
        animation={true} /* todo: jain sort out why not fading out */
        autohide={true}
        delay={3000}
      >
        <Toast.Header className={'position-relative'}>
          <div className={'d-flex flex-column'}>
            {msgArr.map((msg, index) => <p className={'me-auto text-dark m-0 pe-2'} key={'tst'+index}>{msg}</p>)}
          </div>
        </Toast.Header>
      </Toast>
    )
  }

  return (
    <div id={"app"} ref={appRef} className={searchState.industry + "-bg"}>
      <BrowserRouter basename={`/${process.env.PUBLIC_URL}`}>
        <ScrollToTop elemRef={appRef}/>

        {!viewerState.fullscreen && <header className={"w-100 position-fixed"}>
          <HeaderFeedback/>
          <HeaderUCSF />
          <HeaderIDL />
          <BreadCrumb />
        </header>}

        <main>
          <Routes >
            <Route path={"/"} element={<Home />} />
            <Route path={"/home"} element={<Home />} />
            <Route path={"/search"} element={<Home />} />
            <Route path={"/industries"} element={<Home />} />

            <Route path={"/:industries"} element={<Industries />} />
            <Route path={"/:industries/:category/"} element={<Industries />} />
            <Route path={"/:industries/:category/:id"} element={<Industries />} />

            <Route path={"/:industries/:category/viewer"} element={<Viewer />} />

            <Route path={"/news"} element={<Content context={Constants.NEWS} />} />
            <Route path={"/news/:id"} element={<Content context={Constants.NEWS} />} />
            <Route path={"/about-idl"} element={<Content context={Constants.CATEGORY} />} />
            <Route path={"/about-idl/:id"} element={<Content context={Constants.CATEGORY} />} />
            <Route path={"/resources"} element={<Content context={Constants.CATEGORY} />} />
            <Route path={"/resources/:id"} element={<Content context={Constants.CATEGORY} />} />
            <Route path={"/placeholder/:id"} element={<Content context={Constants.CATEGORY} />} />
            <Route path={"/help"} element={<Content context={Constants.CATEGORY} />} />
            <Route path={"/help/:id"} element={<Content context={Constants.CATEGORY} />} />

            <Route path={"/my-library"} element={<MyLibrary />} />
            <Route path={"/preview"} element={<Preview />} />

            <Route path={"/log-in"} element={<Login />} />
            <Route path={"/resetpassword"} element={<ResetPassword />} />
            <Route path={"/my-library/settings"} element={<Settings />} />

            <Route path={"*"} element={<NoPathMatch />}/>
          </Routes>
        </main>

        {/* Toasts */}
        {(toastsState.toasts.length > 0) &&
          <ToastContainer className={"p-3"} position={"middle-center"}>
            {toastsState.toasts.map((toast) => {
              return toastELem(toast)}
            )}
          </ToastContainer>}

        {/* Login modal - accessible throughout application */}
        <LoginModal
          show={loginModalState.active}
          onHide={()=>dispatch(setLoginModalActive(false))}
        />

      </BrowserRouter>
    </div>
  );
}

export default App;
