import React, {useState, createContext} from 'react';

const HistoryContext = createContext(null);

interface IProps {
  children: React.ReactNode;
}

const HistoryProvider = (props: IProps) => {
  const {
    children
  } = props;
  const [history, setHistory] = useState([]);

  return (
    <HistoryContext.Provider value={{ history, setHistory }}>
      {children}
    </HistoryContext.Provider>
  );
}

export { HistoryContext, HistoryProvider };
