import './styles.scss';
import React, {Dispatch, useContext, useEffect, useState} from "react";

/* types, constants, utils */
import * as Constants from "../../../constants";
import Utils from "../../../utils";
import {IViewerRecordContext, ViewerRecordContext} from "../../../types/document";

/* bootstrap */
import {Accordion, Nav} from "react-bootstrap";

/* Redux */
import {ISavedState} from "../../../types/redux/saved";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {useQuery} from "react-query";
import SearchService from "../../../api/search";
import {AnyAction} from "@reduxjs/toolkit";
import {setPossibleDuplicates} from "../../../redux/slices/viewer-slice";
import usePlatform from "../../../hooks/usePlatform";

interface IProps {
  fileType: string | undefined;
  activeRecordTool: string;
  setActiveTool(key: string): void;
}

const ViewerTools = (props: IProps):JSX.Element => {
  const {
    fileType,
    activeRecordTool,
    setActiveTool
  } = props;

  const record: any = useContext<IViewerRecordContext>(ViewerRecordContext).viewerRecord;
  const viewerRecordContext: IViewerRecordContext = useContext<IViewerRecordContext>(ViewerRecordContext);
  const hasBates: boolean = (viewerRecordContext.viewerRecord && viewerRecordContext.viewerRecord.bn);
  const [hasDuplicates, setHasDuplicates] = useState<boolean>(false);
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const saveState: ISavedState = useSelector<RootState, ISavedState>(state => state.saved);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const isDesktop = usePlatform().isDesktop;

  /* need to avoid race condition with redux */
  const [signature, setSignature] = useState<string | null>(null);
  const [id, setID] = useState<string | null>(null);
  /*
    To render an accordion that’s expanded by default:
    add the .show class on the .accordion-collapse element.
    drop the .collapsed class from the .accordion-button element and set its aria-expanded attribute to true.
  */

  const {data, isSuccess, refetch} = useQuery<any, Error> (
    ['duplicates-'+ signature + id],
    async ({signal}) => {
      return await SearchService.solrDuplicatesQuery(signature, id, signal)
    },
    {
      staleTime: Infinity,
      enabled: false
    }
  );

  useEffect(()=> {
    if (isSuccess) {
      const docs: any[] = data.response.docs;
      dispatch(setPossibleDuplicates(docs));
      setHasDuplicates(docs.length > 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data])

  useEffect(()=>{
    /* when opened programmatically accordion internal state remains closed w/o a way to updated it,
      so unfortunately, a click is required */
    let element: Element = document.querySelector('.accordion-button');
    if (!element) return ;

    var event: MouseEvent = new MouseEvent('click', {
      'view': window,
      'bubbles': true,
      'cancelable': true,
      'screenX': element.getBoundingClientRect().left,
      'screenY': element.getBoundingClientRect().top
    });
    element.dispatchEvent(event);
  }, []);

  useEffect(()=>{
    if (!record) return;
    setIsSaved(saveState.documents.find(savedDocument => savedDocument.documentId === record.id) !== undefined);
    setID(record.id);
    setSignature(record.signature);
  }, [saveState.documents, record, record?.id])

  useEffect(()=>{
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record?.signature])

  const handleSelect = (key: string | null): void => {
    if (!key) return;
    setActiveTool(key)
  }

  const navItem = (key: string, label: string, icon: string | null, disabled: boolean = false): JSX.Element =>{
    return (
      <Nav.Item >
        <Nav.Link
          disabled={(document === null) || disabled}
          className={'text-black ps-3 text-normal' +
            ((document === null) || disabled ? ' text-black-50': '') +
            ((activeRecordTool === key) ? ' active': '')}
          eventKey={key}
        >
          {icon && <i className={'bi me-1 ' + icon}/>}
          <span className={(!icon? 'ms-4': '')}>{label}</span>
        </Nav.Link>
      </Nav.Item>
    )
  }

  const recordsAccordion = ():JSX.Element=>{
    return (
      <>
        <Accordion className={'accordion-flush open'} defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0" className={'border-0'} >
          <Accordion.Header>
            <i className={'bi me-2 bi-files'}/>
            <span>{Utils.toTitleCase(Constants.RECORDS)}</span>
          </Accordion.Header>

          <Accordion.Body className={'p-0'}>
            <Nav className={'d-block'} activeKey={activeRecordTool}>
              {navItem(Constants.SEARCH_RESULTS, Utils.toTitleCase(Constants.SEARCH_RESULTS), null)}
              {navItem(Constants.BROWSE, Utils.toTitleCase(Constants.BROWSE), null)}
              {hasBates && navItem(Constants.BATES, Utils.toTitleCase(Constants.BATES), null)}
              {navItem(Constants.MORE_LIKE_THIS, Utils.toTitleCase(Constants.MORE_LIKE_THIS), null)}
              {hasDuplicates && navItem(Constants.POSSIBLE_DUPLICATES, Utils.toTitleCase(Constants.POSSIBLE_DUPLICATES), null)}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        </Accordion>
      </>)
  }

  return (
    <div className={'viewer-tools h-100 border-end py-1'}>
      <Nav
        className={'flex-column'}
        onSelect={key => handleSelect(key)}
      >
        {(!isDesktop) && navItem(Constants.DOCUMENT, Utils.toTitleCase(Constants.DOCUMENT), 'bi-file-text')}
        {navItem(Constants.METADATA, Utils.toTitleCase(Constants.METADATA), 'bi-file-earmark-code')}
        {(!isDesktop) && navItem(Constants.RECORDS, Utils.toTitleCase(Constants.RECORDS), 'bi-files')}
        {isDesktop && <div>{recordsAccordion()}</div>}
        {navItem(Constants.CITE, Utils.toTitleCase(Constants.CITE), 'bi-quote')}
        {navItem(Constants.TAGS_NOTES, Utils.toTitleCase(Constants.TAGS_NOTES), isSaved? 'bi-bookmark-x' : 'bi-bookmark')}
        {navItem(Constants.DOWNLOAD, Utils.toTitleCase(Constants.DOWNLOAD), 'bi-download', (fileType === Constants.VIDEO))}
        {navItem(Constants.SHARE, Utils.toTitleCase(Constants.SHARE), 'bi-share')}
        {navItem(Constants.REPORT_ISSUES, Utils.toTitleCase(Constants.REPORT_ISSUES), 'bi-exclamation-circle')}
      </Nav>
    </div>
  )
}

export default ViewerTools;
