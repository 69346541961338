/* todo: jain - break this class up - too large */

import './styles.scss';
import React, {Dispatch, useCallback, useEffect, useRef, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import Utils from "../../../utils";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {IViewerState} from "../../../types/redux/viewer";
import {RootState} from "../../../redux";
import {
  addToViewed,
  setCurrentPage,
  setDocumentLoaded,
  setDocumentRendered,
  setNumSearchResults,
  setOcrAvailable,
  setPrevZoom, setSearchPanelOpen, setSearchTerm,
  setTotalPages,
  setZoom
} from "../../../redux/slices/viewer-slice";

/* External Modules */
import {Document} from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

/* Bootstrap */
import {Col, Container, Row} from "react-bootstrap";

/* Components */
import Spinner from "../../util/spinner";

/* hooks */
import useLoadPdf from "./hooks/useLoadPdf";
import {useForceUpdate} from "../../../hooks";

/* External modules */
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { pdfjs } from 'react-pdf';
import {ISearchState} from "../../../types/redux/search";
import SearchUtils from "../../../utils/search-utils";
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

interface IProps {
  id: string
  url?: string
}

const ViewerPDF = (props: IProps): JSX.Element => {
  const {
    id,
    url
  } = props;

  /* redux */
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const dispatch:Dispatch<AnyAction> = useDispatch();

  /* scrolling */
  const pdfContainerRef = useRef<HTMLInputElement | null>(null);
  const thumbnailsContainerRef = useRef<HTMLInputElement | null>(null);
  const scrollingToPage = useRef<boolean>(false);
  const scrollTimeout = useRef<number>(0);

  /* Page Context (OCR text) */
  const [pagesTextContext, setPagesTextContext] = useState<any | null>(null );

  /**/
  const [documentURL, setDocumentURL] = useState(null);
  const zoomRef = useRef<HTMLInputElement | null>(null);
  const viewerRef = useRef<HTMLInputElement | null>(null);

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [loadProgress, setLoadProgress] = useState<{loaded: number, total:number}>({loaded: 0, total:0});
  const [loadStartTime, setLoadStartTime] = useState<number>(0);
  const [scrollPercentage, setScrollPercentage] = useState<number>(0);

  const forceUpdate = useForceUpdate();

  const calculateZoom = (): void => {
    if ((!viewerRef.current) || (viewerRef.current.clientWidth === 0)) return;

    let zoom: number;
    switch (viewerState.zoom_select.key) {
      case Constants.FIT_WIDTH:
        zoom = ((viewerRef.current.clientWidth - 10)/Constants.VIEWER_MEDIA_WIDTH);
        break;

      case Constants.PAGE_FIT:
        zoom = 1;
        break;

      case Constants.ACTUAL_SIZE:
        zoom = 1;
        break;

      default:
        zoom = parseFloat(viewerState.zoom_select.key)
    }

    if (zoom !== viewerState.zoom) {
      dispatch(setZoom(zoom));
    }
  }

  useEffect(()=>{
    if (!id) return;
    /* todo: jain - this works but is temporary measure - should not redaw if url is the same */

    setDocumentURL(null);
    setTimeout(()=>{
      if (url) {
        setDocumentURL(url);
      } else {
        setDocumentURL(Utils.getBucketPath(id) + '/' + id +'.pdf');
      }
    }, 500)
  }, [id, url, viewerState.recordTool])

  /*
       Loading
  */
  /* Forced unloading of pages since live outside of state */
  useEffect(()=>{
    /* New Record loaded */
    calculateZoom();
    dispatch(setSearchPanelOpen(false));
    dispatch(setSearchTerm(''));
    dispatch(setTotalPages(0));
    dispatch(setDocumentLoaded(false));
    dispatch(setDocumentRendered(false));
    if (pdfContainerRef.current) {
      pdfContainerRef.current.scrollTop = 0;
    }
    setScrollPercentage(0);
    setLoadStartTime(new Date().getTime());
    setLoadProgress({loaded:0, total:0})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, searchState.query])

  const getContext = async (numPages: any, _transport: any): Promise<any> => {
    const context: any = {}
    for (let i = 0; i < numPages; i++) {
      let pageData = await _transport.getPage(i+1);
      let textContent = await pageData.getTextContent();
      context[i+1] = textContent.items;
    }
    setPagesTextContext(context);
  }

  const onDocumentLoadSuccess = ({ numPages, _transport }: {numPages: number, _transport:any }): void => {
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
    /* @ts-ignore*/
    const pageNum: number =  searchParams.get(Constants.VIEWER_PAGE_KEY) ? searchParams.get(Constants.VIEWER_PAGE_KEY) : 1
    dispatch(setCurrentPage(pageNum));
    dispatch(setTotalPages(numPages));
    dispatch(setDocumentLoaded(true));
    dispatch(addToViewed(id));

    getContext(numPages, _transport).then(()=>{
      dispatch(setOcrAvailable(true));
    })
  }

  const onDocumentLoadError = (e:any): void => {
    dispatch(setTotalPages(0))
  }


  /* Loading hook */
  const {
    pages,
    thumbnails,
    loadPages,
    loadThumbnails
  } = useLoadPdf();

  const reloadPages = ():void =>{
    const blockSize: number = (viewerState.zoom < 1) ? Math.round(4 * (1/viewerState.zoom)) : Constants.PDF_PAGE_LOAD_SIZE;
    loadPages(viewerState.currentPage, blockSize);

    forceUpdate();
  }

  /* Called initially and then once document after loaded */
  useEffect(()=>{
    if (!viewerState.documentLoaded) return;

    /* Initial loading of pages and thumbnails */
    loadPages(viewerState.currentPage, Constants.PDF_PAGE_LOAD_SIZE);
    loadThumbnails(0, Constants.PDF_THUMBNAIL_LOAD_SIZE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerState.documentLoaded])

  /*
     Search
  */

  const performDocSearch = (terms: string): any[] =>{
    let matches: any[] = [];
    if ((terms.length === 0 ) || (pagesTextContext.length === 0 )) {
      return []
    }

    let query: string = terms.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    const regex: RegExp = new RegExp(query, "gi");
    for (let key in pagesTextContext) {
      for (let i=0; i<pagesTextContext[key].length; i++) {
        const match:RegExpExecArray | null = regex.exec(pagesTextContext[key][i].str);
        if (match && (match.length > 0)){
          const match: any = {...pagesTextContext[key][i]};
          match.page = parseInt(key);
          matches = matches.concat(match)
        }
      }
    }

    return matches
  }

  useEffect(()=> {
    if (pagesTextContext) {
      clearAllHighlights();

      let matches: any[] = [];
      if (viewerState.searchTerm) {
        matches = performDocSearch(viewerState.searchTerm);
        dispatch(setNumSearchResults(matches.length));
      } else {
        /* new document, search terms  available */
        const queryTerms: string | null = SearchUtils.searchQueryTerms(searchState.query);
        if (queryTerms) {
          matches = performDocSearch(queryTerms);
        }
      }
      setSearchResults(matches);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerState.searchTerm, pagesTextContext]);

  const clearAllHighlights = ():void => {
    let elems: NodeListOf<Element> | null = document.querySelectorAll('.selected');
    for (let i=0; i<elems.length; i++){
      elems[i].remove();
    }
  }

  const highlightResult = useCallback((index: number, scrollToPage: boolean):void=> {
    const item: any = searchResults[index];
    if (!item) {
      console.log('ERROR; highlightResult item is undefined')
      return
    }
    //eg: {"page": 1, "str":"Yale University","dir":"ltr","width":135.95769000000016,"height":20,"transform":[19.8,0,0,20,117.36,659.76],"fontName":"g_d1_f31R","hasEOL":false} */
    const transform: string[] = item.transform;

    let elem: HTMLElement | null = document.querySelector('.page_' + item.page);
    if (elem) {
      let div = document.createElement('div');
      div.classList.add('selected');
      div.style.bottom = parseFloat(transform[5]) * viewerState.zoom  + 'px';
      div.style.left = parseFloat(transform[4]) * viewerState.zoom + 'px';
      div.style.height = parseFloat(transform[3]) + (viewerState.zoom - 1) * 6 + 'px';
      div.style.width = parseFloat(item['width']) * viewerState.zoom + 'px';
      elem.append(div);
    }

    if (scrollToPage) {
      if (pdfContainerRef.current) {
        const pageHeight: number = ((pdfContainerRef.current.scrollHeight)/viewerState.totalPages);
        const offset: number = (parseInt(transform[5] + 200) * viewerState.zoom); /* todo: 200 is arbitrary here, need to isolate the cause for scroll backwards */
        pdfContainerRef.current.scrollTo({top: pageHeight * item.page - offset, behavior: "smooth"});
      }

      dispatch(setCurrentPage(item.page))
    }
  }, [searchResults, viewerState.totalPages, viewerState.zoom, dispatch])

  useEffect(()=>{
    clearAllHighlights();

    if (searchResults.length > 0) {
      if (viewerState.highlightAllResults) {
        searchResults.forEach((result:any, index: number)=> {
          highlightResult(index, false)
        });
      }
      highlightResult(viewerState.currentSearchResult, true);
    }
  }, [searchResults, viewerState.currentSearchResult, dispatch, highlightResult, viewerState.highlightAllResults, viewerState.zoom])

  /*
     Scrolling
  */
  const getPageNumInView =(): number => {
    if (!viewerState.totalPages) return 0;
    let pageNum: number = 0;
    if (pdfContainerRef.current && zoomRef.current) {
      const pageHeight: number = (pdfContainerRef.current.scrollHeight)/viewerState.totalPages;
      pageNum = Math.round((pdfContainerRef.current.scrollTop /pageHeight)) + 1;
    }
    return pageNum;
  }

  const startScrolling = (): void=>{
    scrollingToPage.current = true;
    const onscrollend = (event:any) =>  scrollingToPage.current = false;
    pdfContainerRef.current.addEventListener("scrollend", onscrollend);
  }

  const updatePageNumber = useCallback((): void => {
    if (!pdfContainerRef.current || scrollingToPage.current) return;

    const pageInView: number = getPageNumInView();
    if (pageInView !== viewerState.currentPage) {
      dispatch(setCurrentPage(pageInView))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, viewerState.currentPage])

  /* Scroll to selection */
  useEffect(()=> {
    if (!viewerState.totalPages || !pdfContainerRef.current) return;

    if (viewerState.contentView === Constants.OCR) {
      startScrolling()
      const elem: HTMLElement | null = document.querySelector('.ocr-pg' + viewerState.currentPage);
      if (elem) {
        pdfContainerRef.current.scrollTo({top: elem.offsetTop, behavior: "smooth"});
      }
    } else {
      /* handle change page from buttons on toolbar */
      if (getPageNumInView() !== viewerState.currentPage) {
        startScrolling()
        /* assumed page number changed not via scrolling (below) but from PageNavigation component or clicking on thumbnail */
        const pageHeight: number = ((pdfContainerRef.current.scrollHeight)/viewerState.totalPages);
        pdfContainerRef.current.scrollTo({top: pageHeight * (viewerState.currentPage - 1), behavior: "smooth"});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerState.currentPage,  viewerState.totalPages, viewerState.contentView]);

  /* Called whenever pdf scrolls */
  const handlePDFScrolling = (): void => {
    if (viewerState.contentView === Constants.OCR) {
      /* todo: jain - need to update numbers here*/
      updatePageNumber();
    }

    window.clearTimeout(scrollTimeout.current)
    scrollTimeout.current = window.setTimeout(()=>{
      if (!pdfContainerRef.current) return;

      setScrollPercentage(pdfContainerRef.current.scrollTop / pdfContainerRef.current.scrollHeight)
      reloadPages()
      updatePageNumber();
    }, 10)
  }

  const handleThumbnailScroll = ()=> {
    if (!thumbnailsContainerRef.current) return;

    const thumbnailHeight: number = (thumbnailsContainerRef.current.scrollHeight)/viewerState.totalPages - 12;
    const currentThumbnail = Math.floor((thumbnailsContainerRef.current.scrollTop /thumbnailHeight)) + 1;
    loadThumbnails(currentThumbnail, Constants.PDF_THUMBNAIL_LOAD_SIZE);
    forceUpdate();
  }

  /*
     Zoom
  */
  useEffect(()=> {
    reloadPages();
    setTimeout(()=> {
      if (zoomRef.current) {
        zoomRef.current.style.visibility = 'visible';
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },  [zoomRef.current?.style.width])

  const zoomPages =()=> {
    if (!zoomRef.current || !pdfContainerRef.current || !id) return;

    if (viewerState.contentView === Constants.OCR) {
      /* todo jain - not currently handled */
      return;
    }

    zoomRef.current.style.width = (Constants.VIEWER_MEDIA_WIDTH * viewerState.zoom) + 'px';
    forceUpdate();

    setTimeout(()=>{
      if (pdfContainerRef.current) {
        pdfContainerRef.current.scrollTop = (scrollPercentage * pdfContainerRef.current.scrollHeight);
      }
    })

    dispatch(setPrevZoom());
  }

  useEffect(()=> {

    /* handle zoom from Zoom component on toolbar */
    if (pages.length > 0) {
      zoomPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerState.zoom]);

  useEffect(()=> {
    if (viewerState.documentRendered) {
      calculateZoom()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerState.thumbnailsCollapsed, viewerState.panelCollapsed, viewerState.zoom_select])

  /*
    OCR Text
  */
  const getPageText = (items: any[]): string =>{
    let txt: string = '';
    for (let i= 0; i<items.length; i++) {
      txt += items[i].str
    }
    return txt;
  }

  const processOCRText = ():JSX.Element => {
    return <>
      {Object.keys(pagesTextContext).map((pageNum:string) =>{
        return <div key={'ocrpg-' + pageNum} className={'ocr-pg'+pageNum}>
          <p className={'mt-3 mb-1'}># Page {pageNum}</p>
            {getPageText(pagesTextContext[pageNum])}
        </div>
      })}
    </>
  }

  useEffect(()=> {
    if (!zoomRef.current || !pdfContainerRef.current) return;

    if (viewerState.contentView === Constants.OCR) {
      dispatch(setZoom(1));
      dispatch(setPrevZoom());
      zoomRef.current.style.top = '0px';
      zoomRef.current.style.transform = 'none';
    }

  }, [viewerState.contentView, dispatch]);

  const displayProgress = (): JSX.Element => {
    if ((loadProgress.total > 5000000) || ((new Date().getTime() - loadStartTime) > 4000)) {
      
      return <div className={'progress-container d-flex align-items-center justify-content-center w-100'}>
        <div className={'w-50'}>
          <p className={'m-0 p-0 font-medium'}>Loading PDF....</p>
          <div className={'progress shadow'}>
            <div
              className={'progress-bar bg-progress-opacity-25'}
              role={'progressbar'}
              style={{width: loadProgress.loaded/loadProgress.total * 100 + '%'}}
              aria-valuenow={loadProgress.loaded/loadProgress.total * 100}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </div>
      </div>

    } else {
      return <div className={'spinner-container'}>
        <Spinner size={Constants.MEDIUM}/>
      </div>
    }
  }

  return (
   <Container className={'viewer-pdf p-0'}>
      <Row className={'viewer m-0 flex-nowrap'}>

        {/* Thumbnails */}
        <Col className={'thumbnail-container bg-white p-0 text-center border-end ' + (viewerState.thumbnailsCollapsed ? ' hidden': '')}>
          <div
            className={'overflow-scroll my-1'}
            ref={thumbnailsContainerRef}
            onScroll={()=>handleThumbnailScroll()}
          >
            <Document
              className={'mt-2'}
              file={documentURL}
              loading={''}
              onItemClick={()=>{}}
            >
              {thumbnails.map((tn:JSX.Element)=>tn)}
            </Document>
          </div>
        </Col>

        {/* PDF & OCR TEXT */}
        <Col ref={viewerRef}  className={'p-0 position-relative'}>
          {((!viewerState.documentLoaded) ||
              ((viewerState.contentView === Constants.OCR) && (!pagesTextContext))) &&
            displayProgress()}

          {documentURL && <div
            ref={pdfContainerRef}
            className={'overflow-scroll position-relative d-flex justify-content-center ' + (viewerState.contentView + '-view')}
            onScroll={()=>handlePDFScrolling()}
          >
            <div ref={zoomRef} className={'zoom-ref position-absolute' + ((viewerState.contentView === Constants.OCR) ? ' w-100' : ' py-1')}>
              <Document
                className={viewerState.contentView}
                file={documentURL}
                onLoadProgress={({loaded, total}) => {setLoadProgress({loaded,total})}}
                loading={''}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
              >
                {((viewerState.contentView === Constants.PDF) ||
                    (viewerState.contentView === Constants.TEXT)) &&
                  pages.map((page:JSX.Element)=>page)}

                {((viewerState.contentView === Constants.OCR) && (pagesTextContext)) &&
                  <div className={'bg-white px-4 pt-2 pb-4'}>
                    {processOCRText()}
                  </div>}
              </Document>
            </div>
          </div>}
        </Col>

      </Row>
    </Container>
  )
}

export default ViewerPDF;
