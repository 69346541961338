
/* Types, Constants, Utils */
import {INotification, ISettings, IUserState, UpdateUserAction} from "../../types/redux/user";
import * as Constants from "../../constants";

/* Redux */
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import LoginRegistrationService from "../../api/login-registration";

const isLoggedIn = ():boolean=> {
  const loggedInSessionValue: string | null = sessionStorage.getItem(Constants.LOGIN_SESSION_KEY);
  let loggedIn: boolean = !!(loggedInSessionValue && (loggedInSessionValue === Constants.TRUE));

  /* remember me */
  if (!loggedIn) {
    const rememberMeValue: string | null = localStorage.getItem(Constants.REMEMBER_ME_KEY);
    if (rememberMeValue) {
       LoginRegistrationService.getToken().then(()=>{
         loggedIn = true; // no expiration on remember me
         setLoggedIn(true)
       });
    }
  }
  
  return loggedIn;
}

const settings = (): ISettings => {
  let returnValue: ISettings = {
    search: Constants.SIMPLE_SEARCH,
    search_results: {
      duplicates: false,
      folders: false,
      restricted: false
    },
    sort: Constants.DEFAULT_SORT,
    num_results: Constants.DEFAULT_RESULT_PER_PAGE
  }

  const saved: string | null = localStorage.getItem(Constants.SETTINGS_KEY);
  if (saved) {
    returnValue = JSON.parse(saved);
  }
  return returnValue;
}

const initialState = ():IUserState => {
  return {
    loggedIn: isLoggedIn(),
    notificationsLoaded: false,
    notifications: [],
    settings: settings()
  }
}

export const userSlice = createSlice({
  name: UpdateUserAction,
  initialState: initialState,
  reducers: {
    setLoggedIn: (state: IUserState, action: PayloadAction<boolean>) => {
      const loggedIn: boolean = action.payload;
      state.loggedIn = action.payload;
      if (loggedIn) {
        sessionStorage.setItem(Constants.LOGIN_SESSION_KEY, Constants.TRUE)
      } else {
        sessionStorage.removeItem(Constants.LOGIN_SESSION_KEY);
        sessionStorage.removeItem(Constants.SESSION_KEY_CSRF);
        localStorage.removeItem(Constants.REMEMBER_ME_KEY);
      }
    },

    updateSettings: (state: IUserState, action: PayloadAction<ISettings>) => {
      state.settings = action.payload;
      localStorage.setItem(Constants.SETTINGS_KEY, JSON.stringify(action.payload))
    },

    addNotification: (state: IUserState, action: PayloadAction<INotification>) => {
      state.notifications.push(action.payload)
    },

    removeNotifications: (state: IUserState, action: PayloadAction<number[]>) => {
      const notificationsCln: INotification[] = [...state.notifications];
      const notificationsToDelete: number[] = action.payload;
      notificationsToDelete.forEach(noteToDelete=>{
        const index: number = state.notifications.findIndex(n => noteToDelete === n.id);
        notificationsCln.splice(index,1);
      })
      state.notifications = notificationsCln;
    },

    setNotifications: (state: IUserState, action: PayloadAction<INotification[]>) => {
      state.notificationsLoaded = true;
      state.notifications = action.payload
    },
  }
});

export const {
  addNotification,
  removeNotifications,
  setLoggedIn,
  updateSettings,
  setNotifications
} = userSlice.actions;

export default userSlice.reducer;
