import  './styles.scss'

import React, {useEffect, useState} from 'react'
import {Col, Container, Dropdown, Form, InputGroup, Row} from 'react-bootstrap';
import {useQuery} from "react-query";
import ContentTables from "../../../../../api/content-tables";
import TABLES_METADATA from "../../../../../assets/data/tables.meta.json";

import * as Constants from "../../../../../constants";
import SearchButton from "../../../../../components/search/search-input/search-button";

import {ISearchState} from "../../../../../types/redux/search";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux";
import Paging from '../../paging';

interface IDropDownItem {
  label: string,
  value: string
}

const TABLE_ROW_LIMIT: number = 100;

export const SearchableTable: React.FC<
  {
    fields: any,
    id?: string
  }
> = props => {
  const [api, setApi] = useState<string>(props.fields.api.trim())
  const [tableStructure, setTableStructure] = useState(null)
  const [columnHeaders, setColumnHeaders] = useState<string[]>([]);
  const [columnFields, setColumnFields] = useState<string[]>([]);
  const [content, setContent] = useState<any>(null);
  const industry: string = useSelector<RootState, ISearchState>((state: RootState):any => state.search).industry;
  const [availableSorts, setAvailableSorts] = useState<IDropDownItem[]>([])

  // eslint-disable-next-line
  const [metaData, setMetaData] = useState<any>(null);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<IDropDownItem>({label:'', value: ''});
  const [searchInput, setSearchInput] = useState<string>('');
  const slug: string = window.location.pathname.split('/').pop();

  const {data, isSuccess,  refetch} = useQuery<any, Error>(
    [api],
    async ({signal}) => {
      return await ContentTables.solrContentTableQuery(api, signal)
    },
    {
      staleTime: Infinity
    }
  );

  const parseHeaders = (key: string):string[] => {
    const returnValue: string[] = [];
    tableStructure.forEach((entry: any) => {
      returnValue.push(entry[key])
    })
    return returnValue
  }

  const sortItem = (value:string): IDropDownItem =>{
    return availableSorts.find(item => item.value === value)
  }

  const searchParams = (): URLSearchParams => {
    const apiSplit: string[] = api.split('?');
    return new URLSearchParams(apiSplit[1]);
  }

  const parseFacets = (fields: (string | number)[]): any => {
    const docs: any[] = [];
    for (let i=0; i < fields.length; i++) {
      docs.push({
        email:fields[i],
        num_email:fields[i+1],
        link: `<a class="text-small" href="/${industry}/documents?industry=${industry}&q=null,all,contains,author:(${fields[i]})&db-set=documents">SEARCH: ${fields[i]}</a>`
      });
      i++;
    }
    return {docs, start:0}
  }

  useEffect(()=> {
    if (!isSuccess || !data || !tableStructure) return;

    setColumnHeaders(parseHeaders(Constants.LABEL));
    setColumnFields(parseHeaders(Constants.FIELD));
    setMetaData(data.responseHeader);

    if (data?.facet_counts?.facet_fields?.sender_facet) {
      const dt: any = parseFacets(data.facet_counts.facet_fields.sender_facet);
      dt.numFound = data.response.numFound;
      setContent(dt)
    } else {
      setContent(data.response);
    }

    setPage(data.response.start + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tableStructure, isSuccess]);

  useEffect(()=> {
    if (!TABLES_METADATA) return;

    const getSorts =  (_tableStructure: any[]):IDropDownItem[]=>{
      const sortItems: IDropDownItem[] = [];

      _tableStructure.forEach((field:any) => {
        let value: string = field.field.toLowerCase() + '_sort';
        if (value === 'date') {
          value = 'dateiso';
        }

        sortItems.push({
            label: field.label + ' (Ascending)',
            value: value + ' asc'
          },
          {
            label: field.label + ' (Descending)',
            value: value + ' desc'
          })
      });

      let defaultSort: IDropDownItem = sortItems[0];
      const sortParam: string = searchParams().get(Constants.SORT);
      if (sortParam) {
        defaultSort = sortItem(sortParam)
      }
      setSort(defaultSort);

      return sortItems
    }

    const getTableStructure = (): any[] => {
      let params:URLSearchParams = searchParams()
      params.set(Constants.SORT, sort.value);
      setApi(api.substring(0, api.indexOf('?') + 1) + params.toString().replaceAll(' ', '+'));

      let tableType: string = api.split('/').shift();
      if (tableType === 'salesvisit') {
        const apiDecode: string = decodeURI(api)
        params = new URLSearchParams(apiDecode);
        let collectionCode: string = 'n/a';
        params.forEach((value, key) => {
          if (value.indexOf('collectioncode') !== -1) {
            value = decodeURI(value)
            collectionCode = value.substring(value.indexOf(':')+2, value.length-1)
          }
        });
        tableType  += ('_' + collectionCode);
      }

      return TABLES_METADATA[tableType];
    }

    const _tableStructure: any[] = getTableStructure();
    setAvailableSorts(getSorts(_tableStructure));
    setTableStructure(_tableStructure);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useEffect(()=> {
    const params:URLSearchParams = searchParams();
    params.set(Constants.START, String(page - 1));
    setApi(api.substring(0, api.indexOf('?') + 1) + params.toString().replaceAll(' ', '+'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSubmit = ():void =>{
    const params:URLSearchParams = searchParams();
    if (searchInput.length === 0) {
      params.set('q', '*');
    } else {
      params.set('q', searchInput);
    }
    setApi(api.substring(0, api.indexOf('?') + 1) + params.toString().replaceAll(' ', '+'));
  }

  const hasQuery = ():boolean =>{
    return searchInput.length > 0;
  }

  const sortMenu = (): JSX.Element => {
    return (
      <Dropdown
        onSelect={(eventKey: string | null)=>setSort(sortItem(eventKey))}
      >
        <Dropdown.Toggle className={"btn-transparent border-0 rounded-0 h-100 px-2 text-black text-small"} id="sort-dropdown">
          <span><strong>Sort by:</strong>&nbsp;</span>
          <span className={'me-1'}>{sort?.label}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {availableSorts.map((item: IDropDownItem, i: number) => {
            return <Dropdown.Item
              key={i}
              href={''}
              eventKey={item.value}
            >{item.label}</Dropdown.Item>
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <div className={'searchable-table slug_'+ slug}>
      <Container className={'search-input p-0'}>
        <Row className={'mx-0 mx-lg-5 mt-3 mb-4'}>
          <Container className={'p-0'}>
            <InputGroup
              className={'border rounded'}
            >
              <Form.Control
                className={'search-input-field border-0 bg-white'}
                placeholder={'Search'}
                aria-label={'Search'}
                aria-describedby={'search-input'}
                value={searchInput}

                onChange={e=> {
                  setSearchInput(e.target.value);
                }}

                onKeyDown={e=>{
                  if (e.code === Constants.ENTER) {
                    e.preventDefault();
                    handleSubmit()
                  }
                }}
              />

              <SearchButton
                disabled={!hasQuery()}
                handleSubmit={handleSubmit}/>
            </InputGroup>
          </Container>
        </Row>
      </Container>

      <div className={'toolbar justify-content-end mx-lg-5 mb-2 bg-grey-1 border p-2 text-end'}>
        {/* desktop */}
        <div className={'d-none d-md-block me-3'}>{sortMenu()}</div>

        {(content?.numFound > TABLE_ROW_LIMIT) &&
          <Paging page={page} totalPages={Math.round(content?.numFound/TABLE_ROW_LIMIT)} setPage={setPage}/>}

        {/* mobile */}
        <div className={'d-flex justify-content-end d-md-none'}>{sortMenu()}</div>
      </div>

      <div className={'table px-0 px-lg-5'}>
        <Container className={'border'}>
          <Row className={'table-headers bg-grey-1 border-bottom py-2'}>
            <Col className={'num-col text-normal'}/>
            {columnHeaders.map((header:string, i: number) =>
              <Col className={'col-header p-3 text-normal'} key={i}><strong>{header}</strong></Col>)
            }
          </Row>

          <div className={'scroll-container'}>
            {content && content.docs.map((entry: any, i: number) => {
              return (<Row className={'border-bottom py-3'} key={i}>
                <Col className={'num-col py-2 '}><strong>{content.start + ((page - 1) * (TABLE_ROW_LIMIT - 1)) + i + 1}</strong></Col>

                {columnFields.map((field:string, i:number)=>{
                  // @ts-ignore
                  return <Col className={'content-col py-2 '} key={i} dangerouslySetInnerHTML={{__html: '<div>'+ (entry[field] ? entry[field] : '') +'</div>'}}/>
                })}
              </Row>)
            })}
          </div>
        </Container>
      </div>
    </div>
  )
}
