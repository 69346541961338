import './styles.scss';
import React from "react";
import {useNavigate} from "react-router-dom";
import {NavigateFunction} from "react-router/dist/lib/hooks";

interface IProps  {
  label: string;
  clickFunc?: any;
  link?: string;
  linkArrow?: boolean;
  linkArrowReverse?: boolean
  underline?: boolean;
  disabled?:boolean;
}

const LinkButton = (props: IProps):JSX.Element => {
  const {
    clickFunc,
    link,
    label,
    linkArrow,
    linkArrowReverse,
    underline,
    disabled
  } = props;

  const navigate:NavigateFunction = useNavigate();

  const handleClick = ()=>{
    if (disabled) return;

    if ( clickFunc) {
      clickFunc()
    } else if (link) {
      if (link.indexOf('http') !== -1 ) {
        // external
        window.location.href = link;
      } else {
        // internal
        navigate(link);
      }
    }
  }

  return (
    <button
      disabled = {disabled}
      className={'link-btn btn pt-1 rounded' + (disabled ? ' disabled': '')}
      onClick={() => handleClick()}>
        {linkArrowReverse &&
          <span className={'long-arrow-left'}/>}
          <span className={'label'} dangerouslySetInnerHTML={{__html: label}}></span>

        {linkArrow &&
          <span className={'long-arrow-right'}/>}

        {underline &&
          <div className={'btn-underline'}/>}
    </button>
  )
}

export default LinkButton;
