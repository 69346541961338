/* Type, Constants, Utils */
import * as Constants from "../../../constants";
import {TFilter, TFilterOption, TFilterPivot} from "../../../types/search";
import Utils from "../../../utils";
import {TSavedDocumentTag} from "../../../types/redux/saved";

export interface IUseFilterOptions {
  facetFieldsOptions (filter: TFilter | TFilterPivot, resultsFacetFields: (string | number)[]):(TFilterOption | TFilterPivot)[];
  facetPivotOptions (filter: TFilter, resultsFacetPivot: (string | number)[]): TFilterPivot[];
  filterTagOptions (filter: TFilter, tags:TSavedDocumentTag[]):TFilterOption[];
  filterDBOptions (filter: TFilter):TFilterOption[];
}

/* Redux */
const useFilterOptions = (): IUseFilterOptions => {

  const facetPivotOptions = (filter: TFilter, resultsFacetPivot: (string | number)[]):TFilterPivot[] => {
    /* Build filter options */
    const pivotOptions: TFilterPivot[] = [];
    resultsFacetPivot.forEach((facetPivot: any) => {
      const filterPivotOptions: any = facetPivot.pivot.filter((p: any) => p.value !== 'Master Settlement Agreement'); /* special one off case*/
      const id: string = Utils.convertToKeyOrId(filter.id + '_' + facetPivot.value);

      pivotOptions.push({
        /* root filter option ( select all )*/
        id: id,
        value: facetPivot.value,
        label: Utils.toTitleCase(facetPivot.value),
        field: facetPivot.field,
        fq_group: filter.fq_group,
        count: facetPivot.count,
        options: filterPivotOptions.map((p: any) => {
          /* subordinate filter options */
          const value: string = (p.value.indexOf(' ') !== -1) ? "\"" + p.value + "\"" : p.value;
          return {
            id: Utils.convertToKeyOrId(p.field + '_' + String(p.value)),
            label: Utils.toTitleCase(p.value, false),
            fq_group: filter.fq_group,
            value: value,
            field: p.field,
            count: p.count,
            invert: false
          }
        })
      });
    });

    return pivotOptions;
  }

  const facetFieldsOptions = (filter: TFilter | TFilterPivot, resultsFacetFields: (string | number)[]): (TFilterOption | TFilterPivot)[] => {
    const filterOptions: (TFilterOption | TFilterPivot)[] = [];
    const facetsFields: any[] = [...resultsFacetFields];

    /* @ts-ignore */
    const field: string = filter.facet_field ? filter.facet_field : filter.field;

    /* Build filter options */
    for (let i = 0; i < facetsFields.length; i++) {

      if (facetsFields[i + 1] !== 0) {
        let id: string = Utils.convertToKeyOrId(field + '_' + facetsFields[i]);
        if (facetsFields.filter(field => String(field).toLowerCase() === facetsFields[i].toLowerCase()).length > 1) {  // case of duplicate filter names
          id += i
        }

        const label: string = (field === Constants.DATE_FIELD) ? facetsFields[i].split('-')[0] : facetsFields[i];
        const filterOption: TFilterOption = {
          id: id,
          label: Utils.toTitleCase(label, false),
          fq_group: filter.fq_group,
          value: facetsFields[i],
          field: field,
          invert: false,
          add: Constants.APPEND,
          count: facetsFields[i + 1],
          industry: undefined
        }

        /* @ts-ignore */
        if (filter.facet_ranges) {
          const pivotFilter: TFilterPivot = {...filterOption, ...{options: []}}
          pivotFilter.label = pivotFilter.label.split('-')[0] + 's';
          filterOptions.push(pivotFilter);
        } else {
          filterOptions.push(filterOption);
        }
      }

      i++;
    }
    return filterOptions;
  }

  const filterTagOptions = (filter: TFilter, tags:TSavedDocumentTag[]):TFilterOption[] => {
    const filterOptions: TFilterOption[] = []
    tags.forEach(tag => {
      const filterOption: TFilterOption = {...Constants.DEFAULT_FILTER_OPTION}
      filterOption.id = String(tag.id);
      filterOption.label = tag.text;
      filterOption.fq_group = filter.fq_group;
      filterOption.value = String(tag.id);
      /* @ts-ignore */
      delete filterOption.count; /* todo: get count of tags from backend */
      filterOptions.push(filterOption)
    })
    return filterOptions
  }

  const filterDBOptions = (filter: TFilter):TFilterOption[] => {
    const optionsCln: TFilterOption[] = [...filter.options];
    filter.options.forEach((option, index)=> {
      /* need to clone before setting (read only) */
      optionsCln[index] = {...filter.options[index]}
    });
    return optionsCln
  }

  return {
    facetFieldsOptions,
    facetPivotOptions,
    filterTagOptions,
    filterDBOptions
  }
};

export default useFilterOptions;
