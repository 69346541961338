import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import {TFilterOption} from "../../../types/search";
import * as Constants from "../../../constants";
import Utils from "../../../utils";

/* redux */
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {ISearchState} from "../../../types/redux/search";
import {AnyAction} from "@reduxjs/toolkit";

/* Bootstrap */
import {Form} from "react-bootstrap";
import {FormCheckType} from "react-bootstrap/FormCheck";
import {addRemoveSelectedFilters} from "../../../redux/slices/search-slice";

interface IProps {
  onHandleChange?: (selectedOption: TFilterOption)=>void | null;
  type: FormCheckType;
  filterOption: TFilterOption;
}

const FiltersCheck = (props: IProps):JSX.Element => {
  const {
    type,
    filterOption,
    onHandleChange
  } = props;

  const [selected, setSelected] = useState<boolean>(false);
  const selectedFiltersId: string[] = useSelector<RootState, ISearchState>((state: RootState):any => state.search).selected_filters_ids;
  const dispatch:Dispatch<AnyAction> = useDispatch();

  useEffect(()=>{
    setSelected(selectedFiltersId.indexOf(filterOption.id) !== -1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption, selectedFiltersId]); /* onHandleChange forces update on external selection set */

  const handleToggle = (e:any): void =>{
    e.stopPropagation(); // don't close the menu when option selected
    const toggled: boolean = !selected;

    if (onHandleChange) {
      onHandleChange({...filterOption});
    } else {
      dispatch(addRemoveSelectedFilters({ids: [filterOption.id], method: toggled ? Constants.ADD : Constants.REMOVE}))
    }
  }

  return (
    <Form.Group controlId={filterOption.id + '_controller' } key={filterOption.id + '_fg'}>
      <Form.Check
        key={filterOption.id + '_check'}
        className={'text-larger mt-1 mb-2'}
        type={type}
        label={<span role={'button'} className={'text-smaller'}>
                  <b>{filterOption.label}</b>
                  {filterOption.count &&
                    <span>&nbsp;({Utils.formatNumber(filterOption.count)})</span>}
                </span>}
        onChange={()=>{}}
        checked={selected}
        onClick={e=>handleToggle(e)}
      />
    </Form.Group>
  )
}
export default FiltersCheck;
