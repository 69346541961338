import './styles.scss';
import React, {Dispatch, useState} from "react";

/* Types, Constants, Utils */

/* Bootstrap */
import {Form, Button} from 'react-bootstrap';

/* Redux */
import {addToast} from '../../../redux/slices/toast-slice';
import {AnyAction} from '@reduxjs/toolkit';

/* hooks */
import {useDispatch} from 'react-redux';
import PasswordField from "../../fields-buttons/password-field";
import useLogin from "../hooks/useLogin";

interface IProps {
  setResetPasswordActive(value: boolean): void;
  context: string;
}

const LoginRegistration = (props: IProps): JSX.Element => {
  const {
    context,
    setResetPasswordActive
  } = props;

  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();

  /* state */
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [keepSignedIn, setKeepSignedIn] = useState<boolean>(true);

  const [validated, setValidated] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  /* hooks */
  const login: Function = useLogin();

  const handleSubmit = async (event:any): Promise<any> =>{
    event.preventDefault();
    event.stopPropagation();

    if ((password.length < 5) || (email.length === 0)) {
      setHasErrors(true);
      return;
    } else {
      setError(null);
    }

    const form = event.currentTarget;
    if (form.checkValidity()) {
      const success: boolean = await login(email, password, 'on', context);
      if (success) {
        dispatch(addToast({
          bg: 'bg-success',
          msg: "You are now logged in.",
          id: -1
        }))
      } else {
        setError(new Error('An error occurred.'));
      }
    }
    setValidated(true);
  }

  const handleKeydown = (): void => {
    setError(null);
    setHasErrors(false);
  }

  return (
    <div className={'login-cmp'}>
      <h2 className={'font-bold'}>Welcome back</h2>
      <h5 className={'my-3 font-light text-secondary'}>Log in to your Industry Documents Library account</h5>

      <Form
        noValidate
        validated={validated}
        className={'login-form mt-4'}
        onSubmit={handleSubmit}
      >
        {/* email */}
        <Form.Group className="mb-3" controlId="loginForm.ControlEmail">
          <Form.Label className={'mb-1 font-light text-secondary'}>Email<sup>*</sup></Form.Label>
          <Form.Control
            type={"email"}
            isInvalid={(hasErrors && (email.length === 0))}
            value={email}
            onChange={e=>setEmail(e.target.value)}
          />

          <Form.Control.Feedback type={'invalid'}>
            Please enter a valid email
          </Form.Control.Feedback>
        </Form.Group>

        {/* password */}
        <Form.Group className="mb-3" controlId="loginForm.ControlPassword">
          <PasswordField
            label={'Password'}
            setPassword={setPassword}
            password={password}
            handleKeydown={handleKeydown}
            isInvalid={()=>(hasErrors && (password.length < 5))}
            errorMessage={'A password at least 5 characters long is required.'}
          />

          <Button
            className={'btn btn-link'}
            onClick={()=>setResetPasswordActive(true)}
          >
            Forgot Password?
          </Button>
        </Form.Group>

        {error &&
          <p className={'text-danger'}>We are unable to sign you in. Please verify the email and password.</p>}

        {/* Submit button */}
        <Button
          className={'button-rounded rounded-pill bg-light-blue w-100 py-3'}
          type={'submit'}
        >
          <h6 className={'m-0'}>LOG IN</h6>
        </Button>

        <Form.Group>
          <Form.Check
            className={"form-check my-3 small"}
            type={'checkbox'}
            checked={keepSignedIn}
            onChange={e=>setKeepSignedIn(e.target.checked)}
            label={'Keep me logged in'}
          />
        </Form.Group>
      </Form>

    </div>
  )
}
export default LoginRegistration;
