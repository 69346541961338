import './styles.scss';
import React, {Dispatch, useCallback, useEffect, useMemo, useState} from "react";

/* types, constants, utils */
import * as Constants from "../../constants";
import Utils from "../../utils";
import {CardContext, IFileAttributes, RecordsContext, ViewerRecordContext} from '../../types/document';
import DocumentUtils from "../../utils/document-utils";

/* bootstrap */
import {Button, Col, Container, Row} from "react-bootstrap";

/* redux */
import {useDispatch, useSelector} from "react-redux";
import {addCrumb} from "../../redux/slices/breadcrumb-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {
  setContentView,
  setPanelCollapsed,
  setRecordTool,
  setViewerFullscreen
} from "../../redux/slices/viewer-slice";
import {RootState} from "../../redux";
import {IViewerState} from "../../types/redux/viewer";

/* api */
import SearchService from "../../api/search";
import {useQuery} from "react-query";

/* components */
import ViewerToolbar from "../../components/viewer/viewer-toolbar";
import ViewerPanel from "../../components/viewer/viewer-panel";
import ViewerTools from "../../components/viewer/viewer-tools";
import ViewScrollContainer from "../../components/view/view-scroll-container";

import SavedService from "../../api/saved";
import {setSavedDocuments} from "../../redux/slices/saved-slice";
import {ISavedState, TSavedDocument} from "../../types/redux/saved";
import {IUserState} from "../../types/redux/user";
import { PageContext } from '../../types/app';
import BackSearch from "../../components/viewer/viewer-toolbar/back-search";

import ViewerMobile from "./mobile";
import ViewerDocument from "../../components/viewer/viewer-document";
import BackMenu from "../../components/viewer/viewer-toolbar/back-menu";
import IndustryNavbar from "../../components/industry-navbar";
import usePlatform from "../../hooks/usePlatform";

const Viewer = ():JSX.Element => {
  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);
  const savedState: ISavedState = useSelector<RootState, ISavedState>(state => state.saved);
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);

  /* Param set state */
  const searchParams: URLSearchParams = useMemo(() => new URLSearchParams(window.location.search), []);

  /* record id*/
  const [viewerRecordId, setViewerRecordId] = useState<string | null>(searchParams.get(Constants.ID)); // redux not initialized on initial load
  const [viewerRecord, setViewerRecord2] = useState<any>(null);

  /* initial record id */
  let initialRecordId: string | null = searchParams.get(Constants.INITIAL_ID); // redux not initialized on initial load
  const [initialRecord, setInitialRecord] = useState<any>(null);

  /* public record */
  const [publicRecord, setPublicRecord] = useState<any>(null);

  const platform = usePlatform();
  const recordToolParamValue: string | null = searchParams.get(Constants.RECORD_TOOL_PARAM);
  let defaultRecordTool: string = platform.isDesktop ? Constants.METADATA : null;
  if (recordToolParamValue) {
    defaultRecordTool = (recordToolParamValue === 'null') ? null : recordToolParamValue;
  }

  const [isDesktop, setIsDesktop] = useState(false);

  /* state */
  const activeRecordTool: string = useSelector<RootState, IViewerState>(state => state.viewer).recordTool;
  const [fileAttributes, setFileAttributes] = useState<IFileAttributes | null>(null);

  const setViewerRecord = (value:any): void =>{
    if (!value) return;

    setViewerRecordId(value.id)
    setViewerRecord2(value)
  }
  useEffect(()=>{
    setIsDesktop(platform.isDesktop)
  }, [platform.isDesktop])

  /* Current record */
  const {
    isSuccess: isSuccessRecord,
    data: viewerRecordData,
    refetch: currentRecordRefetch
  } = useQuery<any, Error>(
    [Constants.RECORD + '_id:' + viewerRecordId],
    async ({signal}) => {
      if (viewerRecordId) {
        const response: any = await SearchService.solrSingleDocumentQuery(viewerRecordId, signal);

        /* check if restricted and if so, see retrieve public if available */
        const record: any = response.response.docs[0];
        const type: string = DocumentUtils.getFileAttributes(record).type;
        if ((type === Constants.CONFIDENTIAL) ||
          (type === Constants.COPYRIGHT) ||
          (type === Constants.CONFIDENTIAL)) {
          const publicRecord: any = await SearchService.solrPublicQuery(record.id, record.bn);
          setPublicRecord(publicRecord.response.docs[0]);
        } else {
          setPublicRecord(null);
        }

        /* return current record */
        return response;
      }
    },
    {
      staleTime: Infinity
    }
  );

  /* Initial  record */
  const {
    isSuccess: isSuccessInitialRecord,
    data: initialRecordData
  } = useQuery<any, Error>(
    [Constants.RECORD + '_id:' + initialRecordId],
    async ({signal}) => {
      if (!initialRecordId ) {
        /* link eg. /viewer?id=qfkk0281 */
        initialRecordId = viewerRecordId;
      }
      if (initialRecordId) {
        return await SearchService.solrSingleDocumentQuery(initialRecordId, signal);
      }
    },
    {
      staleTime: Infinity
    }
  );

  const handleToolSelect = useCallback((recordTool: string | null): void => {
    searchParams.set(Constants.RECORD_TOOL_PARAM, recordTool);
    window.history.replaceState(null, '', "?" + searchParams.toString());
    dispatch(setRecordTool(recordTool));
  }, [searchParams, dispatch])

  useEffect(()=>{
    /* Address the case where a tool in mobile selected that is not available in Desktop */
    const checkForToolChange = ()=>{
      /* todo: jain - handle tool select on resizing */
     /* const isNowDesktop: boolean = platform.isDesktop;
      if ((!isDesktop && isNowDesktop) && ((activeRecordTool === Constants.DOCUMENT) || (activeRecordTool === null))) {
        handleToolSelect(Constants.METADATA)
      }
      setIsDesktop(isNowDesktop);*/
    }
    window.addEventListener(Constants.RESIZE, (event) => checkForToolChange());

    return ()=>{
      dispatch(setViewerFullscreen(false))
      window.removeEventListener(Constants.RESIZE, (event) => checkForToolChange());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activeRecordTool, handleToolSelect, isDesktop])

  useEffect(()=>{
    if (!initialRecord) return;

    const savedDocumentData: TSavedDocument | undefined = savedState.documents.find(savedDocument => savedDocument.documentId === initialRecord.id);
    if (!savedDocumentData) {
      SavedService.getSavedDocumentsViaIds([initialRecord.id]).then((response:any) => {
        dispatch(setSavedDocuments(response.savedDocument));
      }).catch(e => {});
    }
  }, [savedState.documents, initialRecord, userState.loggedIn, dispatch]);

  useEffect(()=> {
    if (!viewerRecord) return;

    /* Add to breadcrumb */
    dispatch(addCrumb({
      label: Utils.toTitleCase(viewerRecord.ti),
      href: '/' + Constants.VIEWER,
      level: 3,
      active: true
    }));
  }, [dispatch, viewerRecord])

  useEffect(()=>{
    if (viewerRecord) {
      currentRecordRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRecordTool, currentRecordRefetch, viewerRecordId]);

  useEffect(()=>{
    if (isSuccessInitialRecord) {
      /* More Like This, Bates and Browse based on selection outside viewer */
      if (initialRecordData.response.docs.length > 0) {
        const documentResponse: any = initialRecordData.response.docs[0];
        if (initialRecordData.moreLikeThis) {
          documentResponse.moreLikeThis = initialRecordData.moreLikeThis;
        }
        setInitialRecord(documentResponse)
      }
    }

    /* Load data for document selected from withing the viewer   */
    if (isSuccessRecord) {
      if (viewerRecordData.response.docs.length > 0) {
        dispatch(setContentView(Constants.PDF));
        dispatch(setPanelCollapsed(false));

        if (isDesktop) {
          handleToolSelect(defaultRecordTool);
        }

        const documentResponse: any = viewerRecordData.response.docs[0];
        setFileAttributes(DocumentUtils.getFileAttributes(documentResponse))
        setViewerRecord(documentResponse); /* must be set to null in the case of Records switching between list, initial record won't reload */
      } else {
        handleToolSelect(Constants.RECORD_NOT_FOUND)
        dispatch(setPanelCollapsed(true))
        setViewerRecord(null);
      }
    } else {
      setViewerRecord(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, isSuccessRecord, viewerRecordData, initialRecordData, isSuccessInitialRecord ])

  const icon = ():JSX.Element => {
    if (viewerState.panelCollapsed) {
      return <i className={'bi-chevron-right text-dark'}/>
    } else {
      return <i className={'bi-chevron-left text-dark'} />
    }
  }

  const subheaderIcon = (): string => {
    switch (activeRecordTool) {
      case Constants.DOCUMENT:
        return 'bi-file-text';
      case Constants.RECORDS:
        return 'bi-files'
      case Constants.CITE:
        return 'bi-quote'
      case Constants.TAGS_NOTES:
        return 'bi-bookmark'
      case Constants.DOWNLOAD:
        return 'bi-download'
      case Constants.SHARE:
        return 'bi-share'
      case Constants.REPORT_ISSUES:
        return 'bi-exclamation-circle'
    }
  }

  return (
    <div className={'viewer-view view'}>
      <PageContext.Provider value={{context: Constants.VIEWER}}>
        <CardContext.Provider value={{context:Constants.DOCUMENT}}>
          <RecordsContext.Provider value={{records:[initialRecord]}}> {/* save & notes components */}
            <ViewerRecordContext.Provider value={{
              initialRecord: initialRecord,
              viewerRecord:viewerRecord,
              publicRecord:publicRecord,
              setViewerRecord: setViewerRecord}}
            >
              <ViewScrollContainer>
                <Container className={'page-container' + (viewerState.fullscreen? ' fullscreen': '')}>

                  {/* Mobile back button above header */}
                  {(!isDesktop) && <BackSearch/>}

                  {/* Header */}
                  <IndustryNavbar/>

                  {!viewerState.fullscreen &&
                    <div className={'subheader bg-white shadow-sm mt-lg-4 pb-lg-3'}>

                    {/* Desktop back button below header */}
                    {isDesktop &&
                      <BackSearch/>}

                    {!isDesktop && (activeRecordTool !== null) &&
                      <h5 className={'mb-3 mx-2'}>
                      <i className={'bi me-1 ' + subheaderIcon()}/>
                      {Utils.toTitleCase(activeRecordTool)}
                    </h5>}

                    {/* mobile menu */}
                    {<h5 className={'tool-title d-lg-none px-2' + (activeRecordTool === null ? ' mobile-menu' : ' bold' )}>Document Title</h5>}

                    {/* Document Title */}
                    {<h1 className={'px-2 my-2' + (activeRecordTool === null ? ' mobile-menu' : '' )}>{viewerRecord && viewerRecord.ti}</h1>}

                    {!isDesktop && (activeRecordTool !== null) &&
                      <BackMenu setActiveRecordTool={(value:string)=>handleToolSelect(value)}/>}
                  </div>}

                  <div className={'d-lg-none'}>
                    <ViewerMobile
                      fileAttributes={fileAttributes}
                      activeRecordTool={activeRecordTool}
                      handleToolSelect={handleToolSelect} />
                  </div>

                  <div className={'mt-3 bg-grey-medium rounded-md border d-none d-lg-block'}>
                    <Row className={'m-0 flex-nowrap'}>
                      <ViewerToolbar
                        fileType={fileAttributes?.type}
                        activeRecordTool={activeRecordTool}
                      />
                    </Row>

                    <Row className={'m-0 flex-nowrap'}>
                      {!viewerState.panelCollapsed &&
                        <Col className={'p-0 col-2'}>
                          <ViewerTools
                            fileType={fileAttributes?.type}
                            activeRecordTool={activeRecordTool}
                            setActiveTool={handleToolSelect}
                          />
                      </Col>}

                      <Col className={'tools-panel-col p-0  d-flex flex-row' + (viewerState.panelCollapsed ? ' col-auto': ' col-3')}>
                        <ViewerPanel
                          activeTool={activeRecordTool}
                          setActiveTool={handleToolSelect}
                        />

                        <Button
                          className={'viewer-panel-toggle bg-light p-0 border-0 border-end rounded-0'}
                          onClick={()=>dispatch(setPanelCollapsed(!viewerState.panelCollapsed))}
                        >
                          {icon()}
                        </Button>
                      </Col>

                      <Col className={'mx-auto p-0 viewer-media' + (viewerState.panelCollapsed ? ' w-100': ' col-7')}>
                        {viewerRecord &&
                          <ViewerDocument
                            viewerRecord={viewerRecord}
                            fileAttributes={fileAttributes}/>}
                      </Col>
                    </Row>
                  </div>

                </Container>
              </ViewScrollContainer>
            </ViewerRecordContext.Provider>

          </RecordsContext.Provider>
        </CardContext.Provider>
      </PageContext.Provider>
    </div>
  )
}

export default Viewer;
