import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import Utils from "../../../utils";
import {RecordsContext} from "../../../types/document";
import * as Constants from "../../../constants";
import {ISearchDate, ISearchEntry, ISearchState} from "../../../types/redux/search";

/* Bootstrap */
import {Button, Card, Col,  Container, Nav, Row, Tab} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {addToSelectedResults, removeFromSelectedResults} from "../../../redux/slices/result-slice";

/* Components */
import {IResultsState} from "../../../types/redux/result";
import SearchUtils from "../../../utils/search-utils";
import CardHeader from "../card-header";
import {TQueryItem} from "../../../constants";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useNavigate} from "react-router-dom";

interface IProps  {
  searchEntry: ISearchEntry
}

const SearchCard = (props: IProps):JSX.Element => {
  const {
    searchEntry
  } = props;


  const dispatch:Dispatch<AnyAction> = useDispatch();
  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const searchState: ISearchState = useSelector<RootState, ISearchState>((state: RootState):any => state.search);

  const [searchParam, setSearchParam] = useState<URLSearchParams | null>(null);
  const [selected, setSelected] = useState<boolean>(false);

  //const urlSearchState: ISearchState = SearchUtils.fromDataSource(window.location.search);
  const navigate: NavigateFunction = useNavigate();

  useEffect(()=> {
    if (searchEntry.url) {
      setSearchParam(new URLSearchParams(searchEntry.url.split('?')[1]))
    }
  }, [searchEntry.url])

  useEffect(()=>{
    /* toolbar select all */
    setSelected(resultsState.selected.findIndex(result => result.id === String(searchEntry.id)) !== -1);
  }, [ resultsState.selected, searchEntry.id])

  const getLabel = (): JSX.Element => {
    const searchParamValue: string | null | undefined = searchParam?.get(Constants.QUERY_KEY);
    if (searchParamValue) {
      const queryItems: TQueryItem[] = SearchUtils.convertQueryParams(searchParamValue);
      const query: string = SearchUtils.convertSearchQuery(queryItems);
      const url: URL = new URL(searchEntry.url)
      return <React.Fragment>
        <Button className={"small btn-link p-0"} onClick={()=> navigate(url.pathname + '?' + url.searchParams)}><b>{query}</b></Button>
      </React.Fragment>
    } else {
      return <></>
    }
  }

  const entry = (label: string, value: string | number | JSX.Element): JSX.Element => {
    const cssClass: string = label.toLowerCase().replace(' ', '-');
    if (value) {
      return <>
        <Col lg="2"><b>{label}</b></Col>
        <Col lg="10" className={cssClass}>{value}</Col>
      </>
    }
    return <></>
  }

  const handleSelected = (selected: boolean): void => {
    if (selected) {
      dispatch(addToSelectedResults([String(searchEntry.id)]))
    } else {
      dispatch(removeFromSelectedResults([String(searchEntry.id)]))
    }
  }

  const convertDatesToReadable = (dates: ISearchDate[]):JSX.Element =>{
    return <div className={'dates'}>
      {dates.map((searchDate: ISearchDate) => {
        return <div className={'date'}>
          <span className={'date'}>{searchDate.field}</span>
          <span className={'date-from'}>{searchDate.from}</span>
          <span>&nbsp;-&nbsp;</span>
          <span className={'date-to'}>{searchDate.to}</span>
        </div>
        }
      )}
     </div>
  }

  return (
    <RecordsContext.Provider value={{records:[searchEntry]}}>
      <Card className="search-card m-0 mb-3 p-0">
        <CardHeader
          selected={selected}
          handleSelected={handleSelected}
          getLabel={getLabel} />

        <Card.Body>
          <Tab.Container defaultActiveKey={Constants.METADATA}>
            <Nav variant="tabs" defaultActiveKey={Constants.METADATA} >
              <Nav.Item>
                <Nav.Link eventKey={Constants.METADATA}>Metadata</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={Constants.FILTERS}>Filters</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Container className={'pt-3'}>
                {/* Metadata TAB*/}
                <Tab.Pane eventKey={Constants.METADATA}>
                  <Row className={'doc-content position-relative small'}>
                    {entry('Search Date', new Date(searchEntry.searchDateTime).toLocaleDateString(Utils.getLocalizationStr(), {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }))}
                    {(searchState.dates.length > 0 ) && entry('Date Ranges', convertDatesToReadable(searchState.dates) )}
                    {entry('Record Count', searchEntry.count)}
                    {entry('Industry', searchState.industry)}
                  </Row>
                </Tab.Pane>

               {/* Filters TAB*/}
                <Tab.Pane eventKey={Constants.FILTERS}>
                  <Row className={'doc-content position-relative'}>
                    <Col lg="12">
                      {/* todo: jain - FILTERS REFACTOR */}
                      {/*{convertFilters(urlSearchState.filters)}*/}
                    </Col>
                  </Row>
                </Tab.Pane>

              </Container>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </RecordsContext.Provider>
  )
}

export default SearchCard;
