import './styles.scss';
import React, {Dispatch, useState} from "react";

/* Types, Constants, Utils */
import {ISearchState} from "../../../../types/redux/search";
import * as Constants from "../../../../constants";

/* Bootstrap */
import {Dropdown} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {setResultsPerPage} from "../../../../redux/slices/search-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {updateSettings} from "../../../../redux/slices/user-slice";
import {ISettings, IUserState} from "../../../../types/redux/user";


interface IProps {
  context: string
}
const ResultsPerPageDropdown = (props: IProps):JSX.Element => {
  const {
    context
  } = props;

  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const settings: ISettings = useSelector<RootState, IUserState>(state => state.user).settings;
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const [numPerPage, setNumPerPage] = useState<number>(searchState.results_per_page);

  const handleSelectResultPerPage = (value: string | null):void => {
    if (value){
      if (context === Constants.SETTINGS) {
        dispatch(updateSettings({...settings, ...{results_per_page: value}}))

      }

      const num: number = parseInt(value);
      setNumPerPage(num);
      dispatch(setResultsPerPage(num));
    }
  }

  return (
    <Dropdown onSelect={handleSelectResultPerPage}>
      <Dropdown.Toggle id={'result-per-page-dropdown'} className={'btn' + ((context !== Constants.SETTINGS) ? ' btn-sm btn-light' : '')}>
        {(context !== Constants.SETTINGS) && <span><b>Display: </b>{numPerPage} Per Page </span>}
        {(context === Constants.SETTINGS) && <span>{numPerPage}</span>}
      </Dropdown.Toggle>

      <Dropdown.Menu role="menu">
        <Dropdown.Item role="menuitem" eventKey={"20"}>20</Dropdown.Item>
        <Dropdown.Item role="menuitem" eventKey={"50"}>50</Dropdown.Item>
        <Dropdown.Item role="menuitem" eventKey={"100"}>100</Dropdown.Item>
        <Dropdown.Item role="menuitem" eventKey={"200"}>200</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
export default ResultsPerPageDropdown;
