import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import {RecordsContext} from "../../../types/document";
import * as Constants from "../../../constants";

/* Bootstrap */
import {Button, Card, Col, Container, Nav, Row, Tab} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {IResultsState} from "../../../types/redux/result";
import {ISearchState} from "../../../types/redux/search";
import {addToSelectedResults, removeFromSelectedResults} from "../../../redux/slices/result-slice";

/* Components */
import CardHeader from "../card-header";
import SEARCH_META_DATA from "../../../assets/data/search.meta.json";
import useCardField, {IUseCardField} from "../hooks/useCardField";

/* Third Party */
import copy from "copy-to-clipboard";
import {addToast} from "../../../redux/slices/toast-slice";
import {NavLink, useNavigate} from "react-router-dom";
import Utils from "../../../utils";

interface IProps  {
  record: any,
  index: number
}

const NUM_CITED_ROWS: number = 4;

const BibliographyCard = (props: IProps):JSX.Element => {
  const {
    record,
    index
  } = props;

  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);

  /* state */
  const [selected, setSelected] = useState<boolean>(false);

  /* hooks */
  const {getFieldContent}: IUseCardField = useCardField();
  const navigate = useNavigate();

  useEffect(()=>{
    /* toolbar select all */
    setSelected(resultsState.selected.findIndex(result => result.id === record.id) !== -1);
  }, [resultsState.selected, record.id])

  const getTitle = (): JSX.Element => {
    const resultNumber: number = (searchState.current_page - 1) * searchState.results_per_page + index + 1;
    const link: string = recordLink(record);
    return <React.Fragment>
      <p className={"title small"}>
        <a className={'text-black'} href={link} target={'_blank'} rel={'noopener noreferrer'}><span>{resultNumber}. </span>{getFieldContent('title', record.title).elem}</a>
      </p>
    </React.Fragment>
  }

  const entry = (label: string, value: string | JSX.Element | undefined): JSX.Element => {
    const cssClass: string = label.toLowerCase().replace(' ', '-');
    if (value) {
      return <>
        <Col lg="2" className={'text-normal m-0 my-0 my-sm-1 py-0'}><b>{label}</b></Col>
        <Col lg="10" className={'text-normal m-0 py-0' + cssClass}>{getFieldContent(label, value).elem}</Col>
      </>
    }
    return <></>
  }

  /* todo: remove function from render same as document card */
  const fields = ():JSX.Element[] =>{
    const resultFields: any[] = SEARCH_META_DATA.biblioResultFields;
    const entries: JSX.Element[] = [];
    resultFields.forEach((field: any) =>  {
      for (const fieldId in field) {
        const fieldValues: string[] = field[fieldId];
        if (fieldValues && (fieldValues[0] === Constants.ALL)) {
          /* @ts-ignore*/
          const mappedValues: string[] = SEARCH_META_DATA.biblioAllFields[fieldId];
          switch (fieldId) {
            /* special cases go here */
            case 'documentcited':
              break;

            case 'link':
               entries.push(entry(mappedValues[0], <ul className={'link-list ps-0'}>
                {record.link && record.link.map((link:any, index:number) => {
                  return <li  className={'p-0 m-0'} key={'lnk'+index}><a href={link} target={'_blank'} rel={'noopener noreferrer'}>{getFieldContent('link', link).elem}</a></li>
                })}
              </ul>))
              break;

            default:
              if (record[fieldId]) {
                entries.push(entry(mappedValues[0], record[fieldId]))
              }

          }
        }
      }
    })
    return entries
  }

  const handleSelected = (selected: boolean): void => {
    if (selected) {
      dispatch(addToSelectedResults([record.id]))
    } else {
      dispatch(removeFromSelectedResults([record.id]))
    }
  }

  const copyLink = (): void => {
    try {
      if (copy(record.link[0])) {
        dispatch(addToast({
          bg: 'bg-success',
          msg: Constants.LINK_COPIED,
          id: -1
        }))
      } else {
        console.log(Constants.UNABLE_TO_COPY)
      }
    } catch (e) {
      throw new Error(Constants.UNABLE_TO_COPY)
    }
  }

  const cited = (): [any] => {
    const fieldId: string = 'documentcited';
    const entries: [any] = [[]];
    let docId: string;
    let next: number;
    if (record[fieldId]) {
      for (let i=0; i<record[fieldId].length; i++) {
        docId = record[fieldId][i];
        next = i % NUM_CITED_ROWS;
        if (next > (entries.length - 1)) {
          entries.push([])
        }

        entries[next].push(
          <ul className={'mb-0'}>
            <li key={'cited_' + docId}>
              <Button className={'btn-link ps-0'}
                // eslint-disable-next-line
                onClick={()=>navigate('/' + Utils.convertToKeyOrId(searchState.industry) + '/documents/viewer?id=' + docId)}>{docId}</Button>
            </li>
          </ul>
        )
      }
    }
    return entries;
  }

  const recordLink = (record: any):string =>{
    if (record.link && (record.link.length > 0)) {
      return record.link[0]
    } else {
      return ' '
    }
  }
  return (
    <RecordsContext.Provider value={{records:[record]}}>
      <Card className={'bibliography-card m-0 mb-3 p-0'}>

        <CardHeader
          selected={selected}
          handleSelected={handleSelected}
          getLabel={getTitle}
        />

        <Card.Body>
          <Tab.Container defaultActiveKey={Constants.METADATA}>
            <Nav variant="tabs" defaultActiveKey={Constants.METADATA} className={'ms-2'}>
              <Nav.Item>
                <Nav.Link className={'px-0 px-sm-4 text-black'} eventKey={Constants.METADATA}>
                  <span>Metadata</span>
                  <div className={'tab-indicator mt-2'}>
                    <div className={'backdrop'}/>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link className={'px-0 px-sm-4 text-black'} eventKey={Constants.ABSTRACT}>
                  <span>Abstract</span>
                  <div className={'tab-indicator mt-2'}>
                    <div className={'backdrop'}/>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link className={'px-0 px-sm-4 text-black'} eventKey={Constants.CITED_DOCUMENTS}>
                  <span>Cited documents</span>
                  <div className={'tab-indicator mt-2'}>
                    <div className={'backdrop'}/>
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Container className={'pt-3 px-1 px-lg-2'}>

                {/* Metadata TAB*/}
                <Tab.Pane eventKey={Constants.METADATA} className={'mb-3'}>
                  <Row className={'doc-content position-relative small'}>

                    {fields().map((elem: JSX.Element, index: any) => <Row className={'mb-2'} key={index}>{elem}</Row>)}

                    <Button
                      className={'ms-2 btn ps-2 pe-3 py-1 btn-link border-secondary'}
                      onClick={copyLink}
                    >
                      <div className={'ms-2'}>
                        <i className={'bi bi-clipboard-plus me-2'}/>
                        Copy Publication URL
                      </div>
                    </Button>

                  </Row>
                  <Row className={'d-flex justify-content-end'} >
                    <NavLink className={'publish-link'} to={recordLink(record)} target="_blank" rel="noopener noreferrer">

                      <Button className={'btn p-0 btn-link'}>
                        <div>
                          View Publication
                          <i className={'bi btn-link bi-box-arrow-up-right ms-3'}/>
                        </div>
                        <div className={'btn-underline'}/>
                      </Button>

                    </NavLink>
                  </Row>

                </Tab.Pane>

                {/* Abstract TAB */}
                <Tab.Pane eventKey={Constants.ABSTRACT} className={'mb-3'}>
                  <Row className={'doc-content position-relative'}>
                    <Col lg="12">
                        {record.abstract}
                    </Col>
                  </Row>
                </Tab.Pane>

                {/* Cited Documents TAB */}
                <Tab.Pane eventKey={Constants.CITED_DOCUMENTS}>
                  <Row className={'doc-content position-relative'}>
                    {cited().map(r=>{
                      return r.map((elem: JSX.Element, index: any) => <Col key={'ct' + index} xs={4} sm={3}><Row >{elem}</Row></Col>)
                    })}
                  </Row>
                </Tab.Pane>
              </Container>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>

      </Card>
    </RecordsContext.Provider>
  )
}

export default BibliographyCard;
