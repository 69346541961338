import {Context, createContext, Dispatch, SetStateAction} from "react";

export type IRecordsContext = {
  records: any[];
}
export const RecordsContext: Context<IRecordsContext> = createContext<IRecordsContext>({records:[]})
export const SelectedRecordsContext: Context<IRecordsContext> = createContext<IRecordsContext>({records:[]})

export type IFileAttributes = {
  type: string | undefined,
  size: string | undefined
}

export type IContext = {
  context: string | null;
}
export const CardContext: Context<IContext> = createContext<IContext>({context: null})

export type IViewerRecordContext = {
  initialRecord: any;
  viewerRecord: any;
  publicRecord: any;
  setViewerRecord: Dispatch<SetStateAction<any>> | null;
}

export const ViewerRecordContext: Context<IViewerRecordContext> = createContext<IViewerRecordContext>({
  initialRecord: null,
  viewerRecord: null,
  publicRecord: null,
  setViewerRecord: null
})

