
import {PAGE_QUERY, STAGE_PAGE_QUERY} from './_graphql/pages'
import {POST_QUERY, STAGE_POST_QUERY} from './_graphql/posts'
import * as Constants from "../../constants";
import Utils from "../../utils";

const {
  REACT_APP_LOCALHOST,
  REACT_APP_LOCAL_CMS_HOST,
  REACT_APP_CMS_HOST} = process.env;

const queryMap = {
  pages: {
    query: PAGE_QUERY,
    key: Constants.PAGES,
  },
  posts: {
    query: POST_QUERY,
    key: Constants.POSTS,
  },
  stagePages: {
    query: STAGE_PAGE_QUERY,
    key: Constants.STAGE_PAGES,
  },
  stagePosts: {
    query: STAGE_POST_QUERY,
    key: Constants.STAGE_POST,
  }
}

export const fetchDoc = async <T>(
  collection: string,
  id: string,
  draft?: boolean,
  token?: string | null,
): Promise<T> => {
  // @ts-ignore
  if ((!queryMap[collection]) || (!id)) throw new Error(`Collection ${collection} not found or id is null, id: ${id}`)

  let query: string = queryMap[collection].query;
  if (!parseInt(id)) {
    query = query.replace('where: { id: { equals: $id }}','where: { slug: { equals: $id }}')
  }

  /* @ts-ignore */
  const apiURL: string = (window.location.host === REACT_APP_LOCALHOST) ? REACT_APP_LOCAL_CMS_HOST : REACT_APP_CMS_HOST;
  const doc: T = await fetch(`${apiURL}/api/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // @ts-ignore
      ...(token && draft ? { Authorization: `JWT ${token}` } : {}),
    },
    body: JSON.stringify({
      query: query,
      variables: {
        id
      },
    }),
  })
    ?.then(res => res.json())
    ?.then(res => {
      if (res.errors) throw new Error(res?.errors?.[0]?.message ?? 'Error fetching doc')
      // @ts-ignore
      return res?.data?.[Utils.capitalizeFirstLetter(collection)]?.docs?.[0]
    })

  return doc
}
