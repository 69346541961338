import './styles.scss';
import React, {Dispatch, useCallback, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import {Category, Industry} from "../../../../api/payload/payload-types";
import Utils from "../../../../utils";
import {IBreadCrumbState, TBreadcrumb} from "../../../../types/redux/breadcrumb";

/* Bootstrap */
import {Row} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../../../redux";
import {IContentState} from "../../../../types/redux/content";
import {AnyAction} from "@reduxjs/toolkit";
import {addCrumb, removeLastCrumb} from "../../../../redux/slices/breadcrumb-slice";

/* Components */
import Layout from "../payload-web/Layout";
import Spinner from "../../../../components/util/spinner";
import CollectionToolbar from "../collection-toolbar";

/* Api */
import {fetchDoc} from "../../../../api/payload/fetchDoc";
import {fetchPagesByCategory} from "../../../../api/payload/fetchPageByCategory";

/* Hooks */
import {useLocation, useNavigate, } from "react-router-dom";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import { PageContext } from '../../../../types/app';
import LinkButton from "../../../../components/button-link";
import usePlatform from "../../../../hooks/usePlatform";


const ContentPage = ():JSX.Element => {
  const { REACT_APP_PROD_HOST} = process.env
  const collection: string = (window.location.host === REACT_APP_PROD_HOST) ? Constants.PAGES.toLowerCase() : Constants.STAGE_PAGES;

  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoading, setContentLoading] = useState<boolean>(true);

  const contentState: IContentState = useSelector<RootState, IContentState>((state: RootState):any => state.content);
  const breadcrumb: TBreadcrumb[] = useSelector<RootState, IBreadCrumbState>(state => state.breadcrumb).breadcrumbs;
  const [backLabel, setBackLabel] = useState<string>('');

  const [isCollectionPage, setIsCollectionPage] = useState<boolean>(false)
  const breadcrumbState: IBreadCrumbState = useSelector<RootState, IBreadCrumbState>(state => state.breadcrumb);
  /* hooks */
  const location = useLocation();
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const isMobile: boolean = usePlatform().isMobile;

  useEffect(()=> {
    if (!pageContent || pageContent?.parent) return;

    const titleParts: string[] = pageContent.title.split('-');
    const title: string = titleParts[titleParts.length - 1].trim();
    const href: string = decodeURIComponent(location.pathname).toLowerCase().replaceAll(' ', '-');
    const crumbs: TBreadcrumb[] = breadcrumbState.breadcrumbs;
    if (crumbs[crumbs.length -1].href !== href) {
      dispatch(addCrumb({
        label: Utils.toTitleCase(title),
        href: href,
        level: breadcrumbState.breadcrumbs.length,
        active: true
      }));
    }
    displayBack();
    setIsCollectionPage(!pageContent.parent && (pageContent.categories.title.toLowerCase() === Constants.COLLECTIONS))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageContent]);

  const fetchPageContent = useCallback(async (industry:string, category:string, id: string | null):Promise<void> => {
    if (id) {
      let doc: any = {};
      /* Content page */
      if (window.location.host !== REACT_APP_PROD_HOST) {
        // on stage, need to display stage version
        doc = await fetchDoc(Constants.PAGES.toLowerCase(), id);
        if (doc && doc.stage) {
          id = doc.stage;
          //console.log('Stage id found in record: ' + id)
        } else {
          console.log('Stage Draft not exists for Page id: ' + id)
          id = null;
        }
      }

      if (id) {
        const page: any = await fetchDoc(collection, id);
        setPageContent(page)
      } else {
        console.log('Stage Draft id is null');
      }
      setContentLoading(false);
    } else {
      /* Top level page of industries category */

      /* @ts-ignore */
      const categoryFilter: Category[] = contentState.categories.filter(c=>c.title?.replace(' ', '-').toLowerCase() === category.toLowerCase())
      const categoryId: string | null = (categoryFilter.length > 0) ? categoryFilter[0].id : null;

      /* @ts-ignore */
      const industryFilter: Industry[] = contentState.industries.filter(i=>i.title.replace(' ', '-').toLowerCase() === industry.toLowerCase())
      const industryId: string | null = (industryFilter.length > 0) ? industryFilter[0].id : null;

      let docs: any[] = [];
      if (industryId && categoryId) {
        docs = await fetchPagesByCategory({
          industry: industryId,
          category: categoryId,
          collection: collection,
          parent: true
        });

        if (docs.length > 0) {
          const parentDocs: any[] = docs.filter(d => d.parent);
          if (parentDocs.length > 1) {
            console.log('Error: found two parent docs for industry/category')
          }
          setPageContent(parentDocs[0]);
        } else {
          console.error('Unable to load: ' + industry, category, id)
        }
      }
    }
    setContentLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentState.industries, contentState.categories, window.location.href]);

  useEffect(()=> {
    const pages = async () => {
      /* preview */
      const searchParams: URLSearchParams = new URLSearchParams(location.search);
      const previewPostId: string | null = searchParams.get(Constants.ID);
      const token: string | null = searchParams.get(Constants.TOKEN)

      if (previewPostId && token) {
        const doc: any = await fetchDoc<any>(Constants.PAGES.toLowerCase(), previewPostId, true,  token);
        if (doc) {
          setPageContent(doc);
          setContentLoading(false);
        }
        
      } else if ((contentState.categories.length > 0) && (contentState.industries.length > 0)) {
        const {industry, section, id } = Utils.parseIndustrySection();
        const categoryData: Category | undefined = contentState.categories.find(c=> section === c.title?.replace(' ', '-').toLowerCase());

        if (industry && categoryData && section) {
          fetchPageContent(industry, section, id)
        }
      }
    }

    pages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentState.categories, contentState.industries, window.location.href]);

  const displayBack = (): void =>{
    setTimeout(()=>{
      //console.log(breadcrumb.length)
      setBackLabel(breadcrumb[breadcrumb.length - 1].label)
    })
  }

  const back = (): void => {
    dispatch(removeLastCrumb());
    //navigate(-1);
    navigate(breadcrumb[breadcrumb.length - 2].href)
  }

  return (
    <PageContext.Provider value={{context: Constants.CONTENT}}>
      <div className={'page-view bg-white mb-0 mb-lg-2 mt-0 mt-lg-3 px-3 py-3 py-lg-4 position-relative'}>
        {pageContent && !pageContent.parent &&
          <Row className={'back-btn btn-container justify-content-start'}>
            <LinkButton
              label={isMobile ? 'Back' : `Back to <strong>${backLabel}</strong>`}
              linkArrowReverse={true}
              underline={true}
              clickFunc={back}/>
          </Row>}

        {contentLoading &&
          <div className={'text-center pt-5'}>
            <Spinner size={Constants.MEDIUM}/>
          </div>}

        {!contentLoading &&
          <div className={'pb-3' + (pageContent?.parent ? '' : ' secondary-page') + (isCollectionPage ? ' collection-page': '')}>
            {/* special case: all collection pages have alert and share component */}
            {isCollectionPage &&
              <CollectionToolbar id={pageContent?.slug ? String(pageContent.slug) : String(pageContent.id)}/>}

            {pageContent && <Layout layout={pageContent.layout}/>}
          </div>}
    </div>
    </PageContext.Provider>
  )
}

export default ContentPage;
