export const DOCUMENTSTORE_URL: string = "https://download.industrydocuments.ucsf.edu";

export const ALL_INDUSTRIES: string = 'all-industries';
export const TOBACCO: string        = 'tobacco';
export const OPIOIDS: string        = 'opioids';
export const CHEMICAL: string       = 'chemical';
export const DRUG: string           = 'drug';
export const FOOD: string           = 'food';
export const FOSSIL_FUEL: string    = 'fossil-fuel';

export const ALL_INDUSTRIES_TITLE: string = 'All Industries';
export const TOBACCO_TITLE: string        = 'Tobacco';
export const OPIOIDS_TITLE: string        = 'Opioids';
export const CHEMICAL_TITLE: string       = 'Chemical';
export const DRUG_TITLE: string           = 'Drug';
export const FOOD_TITLE: string           = 'Food';
export const FOSSIL_FUEL_TITLE: string    = 'Fossil Fuel';

export const TOBACCO_ARCHIVE_TITLE: string        = 'Truth Tobacco Industry Documents Library';
export const OPIOIDS_ARCHIVE_TITLE: string        = 'UCSF-JHU Opioid Industry Documents Archive';
export const CHEMICAL_ARCHIVE_TITLE: string       = 'Chemical Industry Documents Archive';
export const DRUG_ARCHIVE_TITLE: string           = 'Drug Industry Documents Archive';
export const FOOD_ARCHIVE_TITLE: string           = 'Food Industry Documents Archive';
export const FOSSIL_FUEL_ARCHIVE_TITLE: string    = 'Fossil Fuel Industry Documents Archive';

export const TRUE: string                         = 'true';

/* todo: jain convert to symbols where appropriate ? */

/* Plural is used for db set */
export const DOCUMENTS: string          = 'documents';
export const COLLECTIONS: string        = 'collections';

export const DOCUMENT: string               = 'document';
export const RECORD: string                 = 'record';
export const COLLECTION: string             = 'collection';
export const BIBLIOGRAPHY: string           = 'bibliography';
export const PUBLICATIONS: string           = 'publications';
export const INDUSTRIES_DOCUMENTS: string   = 'industries-documents';
export const INDUSTRIES_PAGES: string       = 'industries-pages';
export const SEARCHES: string               = 'searches';
export const SEARCH: string                 = 'search';
export const SEARCH_RESULTS: string         = 'search-results';
export const HOME: string                   = 'home';
export const INDUSTRY: string               = 'industry';
export const CONTENT: string                = 'content';
export const SAVED: string                  = 'saved';

export const MY_LIBRARY: string             = 'my-library';
export const MY_LIBRARY_PATHNAME: string    = '/my-library';
export const SEARCH_HISTORY: string         = 'search-history';
export const SETTINGS: string               = 'settings';
export const SETTINGS_KEY: string           = 'settings';
export const SAVED_DOCUMENTS: string        = 'my-documents';
export const SAVED_SEARCHES: string         = 'my-searches';
export const SAVED_BIBLIOGRAPHY: string     = 'my-publications';

export const NEWS: string                     = 'news';
export const RESOURCES: string                = 'resources';
export const ABOUT: string                    = 'about';
export const ABOUT_IDL: string                = 'about-idl';
export const HELP: string                     = 'help';
export const CATEGORY: string                 = 'category';
export const LOGIN: string                    = 'log-in';
export const LOGOUT: string                   = 'log-out';
export const LOGIN_SESSION_KEY: string        = 'logged-in';
export const RESET_PASSWORD: string           = 'reset-password';
export const RESET_PASSWORD_PATHNAME: string  = 'resetpassword';

export const RESET_PASSWORD_TOKEN_KEY: string = 'token';
export const RESET_PASSWORD_EXPIRE_KEY: string  = 'exp';

export const VIEWER: string               = 'viewer';
export const HIGHLIGHTS: string           = 'highlights';
export const DOCUMENTS_TITLE: string      = 'Documents';
export const BIBLIOGRAPHY_TITLE: string   = 'Publications';

export const PDF: string          = 'pdf';
export const ZIP: string          = 'zip';
export const VIDEO: string        = 'video';
export const AUDIO: string        = 'audio';
export const WEBSITE: string      = 'website';
export const CONFIDENTIAL: string = 'confidential';
export const PRIVILEGED: string   = 'privileged';
export const COPYRIGHT: string    = 'copyright';
export const PUBLIC: string       = 'public';
export const FORMERLY_CONFIDENTIAL: string    = 'formerly_confidential';
export const ALL_FILES_ZIP: string = 'All Files (zip)';

export const DUPLICATES: string   = 'duplicates';
export const FOLDERS: string      = 'folders';
export const RESTRICTED: string   = 'restricted';

export const TOP: string    = 'top';
export const BOTTOM: string = 'bottom';
export const NO_TITLE: string = '- No Title - ';

export const DOCS: string = 'docs';

export const MEDIUM: string = 'medium';
export const SMALL: string  = 'small';
export const TINY: string   = 'tiny';

export const METADATA: string         = 'metadata';
export const FILTERS: string          = 'filters';

export const ENTER_KEY: string = 'Enter';
export const BACKSPACE_KEY: string = 'Backspace';

export const DOWN: string = 'down';
export const UP: string = 'up';
export const UP_AND_DOWN: string = 'up-and-down';
export const PAGE: string = 'page';

export const MOUSEDOWN: string = 'mousedown';
export const KEYDOWN: string   = 'keydown';
export const KEYUP: string     = 'keyup';
export const MODAL:string      = 'modal';
export const VIEW: string      = 'view';

export const REMEMBER_ME_KEY: string = 'rm_exp';

export const ACTIVE: string   = 'active'
export const ENTER: string    = 'Enter';
export const BACKSPACE: string = 'Backspace';
export const SEMICOLON: string = 'Semicolon';

export const CARD: string     = 'card';
export const TOOLBAR: string  = 'toolbar';

export const UPDATE: string   = 'update';
export const DISPLAY: string  = 'display';
export const HEADER: string   = 'heading';
export const ATTACHED: string = 'attached';

export const RESIZE: string = 'resize';

export const ACCESSIBILITY_ISSUES: string = 'Accessibility issues';

/* settings */
export const MY_ACCOUNT: string     = 'my-account';
export const GENERAL_SETTINGS: string     = 'general-settings';

/* Error */

