import {ISearchDate, ISearchState, UpdateSearchAction} from "../../types/redux/search";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import * as Constants from '../../constants';
import {TQueryItem} from "../../constants";
import SearchUtils from "../../utils/search-utils";
import {TFilterOption} from "../../types/search";

const reset = (state: ISearchState): void => {
  state.query = [Constants.DEFAULT_SEARCH];
  state.recent_query = false;
  state.dates = [];
  state.current_page = 1;
  state.sort = SearchUtils.sortDefault(window.location.search);
  state.applied_filters = SearchUtils.defaultFilters(state.db_set).default;
  updateFilterParams(state);
}

const resetSaved = (state: ISearchState): void => {
  state.query = [Constants.MY_LIBRARY_EMPTY_SEARCH];
  state.dates = [];
  state.current_page = 1;
  state.sort = Constants.MY_LIBRARY_DEFAULT_SORT;
  state.applied_filters = [];
  updateFilterParams(state);
  state.recent_query = false;

  /* clear url param */
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(Constants.SORT_KEY, state.sort);
  searchParams.set(Constants.RESULTS_PER_PAGE_KEY, String(state.results_per_page ? state.results_per_page : Constants.DEFAULT_RESULT_PER_PAGE));
  window.history.pushState(null, '', "?" + searchParams.toString());
}

const updateFilterParams = (state: ISearchState): void =>{
  const searchParams = new URLSearchParams(window.location.search);
  let searchParamString: string = '';
  if (state.db_set) { // not for content page --> db_set = null
    const filterIds: string[] = state.applied_filters.map(f => f.id);
    if (filterIds.length > 0) {
      searchParams.set(Constants.FILTERS_KEY, filterIds.join(','));
    } else {
      searchParams.delete(Constants.FILTERS_KEY)
    }

    searchParamString = "?" + searchParams.toString();
  }
  window.history.pushState(null, '', searchParamString);
}

const removeFilterParam = ()=>{
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(Constants.FILTERS_KEY);
  let searchParamStr: string = searchParams.toString();
  if (searchParamStr.length > 0) {
    searchParamStr = '?' + searchParamStr;
  }
  window.history.replaceState(null, '', searchParamStr);
}

export const searchSlice = createSlice({
  name: UpdateSearchAction,
  initialState:  SearchUtils.fromDataSource(window.location.search),
  reducers: {
    setSearch: (state: ISearchState, action: PayloadAction<ISearchState>) => {
      return {...action.payload}
    },

    setIndustry: (state: ISearchState, action: PayloadAction<string>) => {
      state.industry = action.payload
    },

    setDBSet: (state: ISearchState, action: PayloadAction<string | null>) => {
      state.db_set = action.payload;
    },

    /* recent_query --> save query in recent queries */
    setQuery: (state: ISearchState, action: PayloadAction<{query:TQueryItem[], recent_query:boolean}>) => {
      state.query = action.payload.query;
      state.applied_filters = SearchUtils.resetFilters(state.applied_filters, Constants.QUERY);
      state.recent_query = action.payload.recent_query;
    },

    clearQuery: (state: ISearchState) => {
      state.query = [] ;
      state.recent_query = false;
    },

    setQueryOfIds: (state: ISearchState, action: PayloadAction<string[]>) => {
      let queryItem: TQueryItem = {...Constants.DEFAULT_SEARCH};

      let query: TQueryItem[] = [];
      if (action.payload.length === 0) {
        queryItem.field = Constants.ID;
        queryItem.term = null;
        query.push(queryItem);
      } else {
        for (let i=0; i<action.payload.length; i++) {
          queryItem = {...Constants.DEFAULT_SEARCH};
          queryItem.field = Constants.ID;
          queryItem.term = action.payload[i];
          if (i > 0) {
            queryItem.operator = Constants.OR;
          }
          query.push(queryItem);
        }
      }
      state.query = [...query];
    },

    setResultsPerPage: (state: ISearchState, action: PayloadAction<number>) => {
      state.results_per_page = action.payload
    },

    setCurrentPage: (state: ISearchState, action: PayloadAction<number>) => {
      state.current_page = action.payload
    },

    setSort: (state: ISearchState, action: PayloadAction<string>) => {
      state.sort = action.payload
    },

    setSearchDates: (state: ISearchState, action: PayloadAction<ISearchDate[]>) => {
      state.dates = action.payload;
    },

    setFiltersKey: (state: ISearchState, action: PayloadAction<string>) => {
      state.filters_key = action.payload;
    },

    setAppliedFilters: (state: ISearchState, action: PayloadAction<TFilterOption[]>) => {
      state.applied_filters = action.payload;
      updateFilterParams(state);
    },

    addFilters: (state: ISearchState, action: PayloadAction<TFilterOption[]>) => {
      let index: number;
      action.payload.forEach(filterOption => {
        index = state.applied_filters.findIndex(f=>(f.id === filterOption.id));
        if (index === -1) {
          if (filterOption.add === Constants.PREPEND) {
            state.applied_filters.unshift(filterOption) // some filters must proceed others eg. duplicates must come first
          } else {
            state.applied_filters.push(filterOption)
          }
        }
      })
      updateFilterParams(state);
    },

    removeFilters: (state: ISearchState, action: PayloadAction<TFilterOption[]>) => {
      let index: number;
      action.payload.forEach(filterOption => {
        index = state.applied_filters.findIndex(f=>(f.id === filterOption.id));
        if (index !== -1) {
          state.applied_filters.splice(index, 1)
        }

        index = state.selected_filters_ids.findIndex(id=>(id === filterOption.id));
        if (index !== -1) {
          state.selected_filters_ids.splice(index, 1)
        }
      })

      updateFilterParams(state);
    },

    clearFilters: (state: ISearchState) => {
      state.applied_filters = [];
      state.selected_filters_ids = [];
      removeFilterParam();
    },

    setSelectedFilters: (state: ISearchState, action: PayloadAction<string[]>) => {
      state.selected_filters_ids = action.payload;
    },

    addRemoveSelectedFilters: (state: ISearchState, action: PayloadAction<{ids:string[], method:string}>) => {
      const {
        ids
      } = action.payload;

      ids.forEach(id =>{
        const index: number = state.selected_filters_ids.findIndex(selectedId=>(selectedId === id));
        if (action.payload.method === Constants.ADD) {
          if (index === -1) {
            state.selected_filters_ids.push(id)
          }
        } else {
          if (index !== -1) {
            state.selected_filters_ids.splice(index, 1)
          }
        }
      })
    },

    clearSelectedFiltersIds: (state: ISearchState) => {
      state.selected_filters_ids = [];
    },

    searchReset: (state: ISearchState) => {
      reset(state);
    },

    searchResetSaved: (state: ISearchState) => {
      resetSaved(state);
    }
  }
});

export const {
  setSearch,
  setIndustry,
  setDBSet,
  setQuery,
  setQueryOfIds,
  clearQuery,
  setResultsPerPage,
  setCurrentPage,
  setSort,
  setSearchDates,
  setAppliedFilters,
  setFiltersKey,
  addFilters,
  removeFilters,
  clearFilters,
  searchReset,
  searchResetSaved,
  setSelectedFilters,
  addRemoveSelectedFilters,
  clearSelectedFiltersIds
} = searchSlice.actions;
export default searchSlice.reducer;
