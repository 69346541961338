import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

/* Data */
import SEARCH_META_DATA from "../../../assets/data/search.meta.json";

/* Types, Constants, Utils */
import Utils from "../../../utils";
import {RecordsContext} from "../../../types/document";
import * as Constants from "../../../constants";

/* Bootstrap */
import {Card, Col, Collapse, Container, Row} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {IResultsState} from "../../../types/redux/result";
import {ISearchState} from "../../../types/redux/search";
import {
  addToSelectedResults,
  removeFromSelectedResults,
  setInitialSelection,
  setSelectedResults
} from "../../../redux/slices/result-slice";
import {IUserState} from "../../../types/redux/user";

/* Hooks */
import {useSearchParams} from "../../../hooks";

/* Components */
import MediaPreview from "../media-preview";
import Tags from "../../tags-notes/tags/tags";
import CardHeader from '../card-header';
import TagsModal from "../../tags-notes/tags/tag-modal";
import Note from "../../tags-notes/notes";
import {ISavedState, TSavedDocument} from "../../../types/redux/saved";
import useCardField, {ICardFieldContent, IUseCardField} from "../hooks/useCardField";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import LinkButton from "../../button-link";

interface IProps  {
  record: any;
  index: number;
}

interface IMetaFields {
  'above-fold': JSX.Element[],
  'below-fold': JSX.Element[]
}


const ABOVE_FOLD: string = 'above-fold';
const BELOW_FOLD: string = 'below-fold';

const DocumentCard = (props: IProps):JSX.Element => {
  const {
    record,
    index
  } = props;

  /* redux */
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const savedState: ISavedState = useSelector<RootState, ISavedState>(state => state.saved);
  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const navigate: NavigateFunction = useNavigate();

  /* state */
  const [expanded, setExpanded] = useState<boolean>(resultsState.expand_all);
  const [selected, setSelected] = useState<boolean>(false);
  const [savedDocumentData, setSavedDocumentData] = useState<TSavedDocument | null>(null);
  const [metaFields, setMetaFields] = useState<IMetaFields>({'above-fold': [], 'below-fold': []})
  /* hooks */
  const {currentSearchParams} = useSearchParams();
  const {getFieldContent}: IUseCardField = useCardField();

  useEffect(()=>{
    /* toolbar expand all */
    setExpanded(resultsState.expand_all);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[resultsState.expand_all, searchState.query])

  useEffect(()=>{
    if (!record) return;
    /* toolbar select all */
    setSelected(resultsState.selected.findIndex(result => result.id === record.id) !== -1);
  }, [resultsState.selected, record?.id, record])

  useEffect(()=>{
    /* saved documents */
    const savedDocument: TSavedDocument | undefined = savedState.documents.find((document: TSavedDocument) => document.documentId === record.id);
    setSavedDocumentData(savedDocument ? savedDocument : null)
  }, [record, savedState.documents])

  const handleLinkClick = (): void =>{
    dispatch(setInitialSelection(record.id));
    dispatch(setSelectedResults([record.id]))
  }

  const getLabel = (): JSX.Element => {
    let title: string;
    if (record.highlighting && record.highlighting.ti) {
      title = record.highlighting.ti;
    } else {
      title = record.ti ? record.ti : 'Unknown Title';
    }

    const resultNumber: number = (searchState.current_page - 1) * searchState.results_per_page + index + 1;
    return (
      <Link
        className={"header-link small text-black"}
        to={'/' + Utils.convertToKeyOrId(record.industry[0]) + '/documents/' + Constants.VIEWER + '?' + Constants.INITIAL_ID + '=' + record.id + '&' + Constants.ID + '=' + record.id + '&' + currentSearchParams().toString()}
        onClick={()=>handleLinkClick()}
      >
        <span>{resultNumber}. </span>
        {getFieldContent('title', title).elem}
      </Link>
    )
  }

  const handleSelected = (selected: boolean): void => {
    setSelected(selected);
    if (selected) {
      dispatch(addToSelectedResults([record.id]))
    } else {
      dispatch(removeFromSelectedResults([record.id]))
    }
  }

  useEffect(()=>{

    const entry = (label: string, value: any, loc: string): JSX.Element => {
      if (value) {
        const fieldContent: ICardFieldContent = getFieldContent(label, value);
        if ((loc === Constants.BELOW) && (fieldContent.count > 0) && !expanded && !resultsState.expand_all) {
          setExpanded(true);
        }
        return <>
          <Col lg={2} className={'text-normal'}><b>{label}:</b></Col>
          <Col lg={10}  className={'text-normal'}>{fieldContent.elem}</Col>
        </>
      }
      return <></>
    }

    const fields: IMetaFields = {
      'above-fold': [],
      'below-fold': []
    }

    const resultFields: any[] = SEARCH_META_DATA.resultFields;
    resultFields.forEach((field: any) =>  {
      for (const fieldId in field) {
        const fieldValues: string[] = field[fieldId];
        if (fieldValues && (fieldValues[0] === Constants.ALL)) {
          let loc: string = (fieldValues.indexOf(Constants.ABOVE) !== -1) ? ABOVE_FOLD : BELOW_FOLD;

          /* special case - field is selected in search --> move to above the fold */
          const terms: string[] = searchState.query.filter(q => q.term !== null).map(q=> q.term).join(' ').split(' ');
          const fieldSelected: boolean =  (terms.findIndex(t=>t === `${fieldId}:${Constants.ASTERISK}`) !== -1)
          if (fieldSelected) {
            loc = ABOVE_FOLD;
          }

          /* @ts-ignore*/
          const mappedValues: string[] = SEARCH_META_DATA.allFields[fieldId];
          switch (fieldId) {
            case 'excerpt':
              const excerpt: string | undefined = (record?.highlighting && record?.highlighting.ot) ?
                Utils.removeTags(record.highlighting.ot.join(', ')).replace(/�|�/g, "") : undefined;
              fields[loc].push(entry('Excerpt', excerpt, loc))
              break;

            case 'availability':
              fields[loc].push(entry('Availability', record[fieldId].join('; '), loc))
              break;

            case 'ark':
              fields[loc].push(entry('ARK', "ark:/88122/" + record.id, loc))
              break;

            case 'cited':
              if (record['journal']) {
                let journal: any[] = JSON.parse(record['journal']);
                fields[loc].push(entry(mappedValues[0], <ol className={'ps-0'}>
                  {journal.map((entry:any, index:number) => {
                    let url: string = window.location.protocol + '//' + window.location.host + '/all-industries/bibliography/?industry=all-industries&db-set=bibliography&npp=20&q=null,all,contains,refid:' + entry.id + '&sort=relevance&pg=1';
                    return <li className={'p-0 m-0 cited'} key={'jrn'+index}>
                      <a href={url} target={'_self'} rel="noreferrer"><i>{getFieldContent(fieldId, entry.title).elem}</i></a>
                    </li>
                  })}
                </ol>, loc))
              }
              break;

            default:
              fields[loc].push(entry(mappedValues[0], record[fieldId], loc))
          }
        }
      }
    })

    setMetaFields(fields);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, searchState.query])

  const handleViewDocumentClick = (): void => {
    dispatch(setInitialSelection(record.id));
    dispatch(setSelectedResults([record.id]));
    const url: string = '/' + Utils.convertToKeyOrId(record.industry) + '/documents/' + Constants.VIEWER + '?' + Constants.INITIAL_ID + '=' + record.id + '&' + Constants.ID + '=' + record.id + '&' + currentSearchParams().toString();
    navigate(url);
  }

  const toggleExpanded = ():void =>{
    setExpanded(!expanded)
  }

  return (
    <RecordsContext.Provider value={{records:[record]}}>
      <Card className={"document-card m-0 mb-3 p-0 pb-3"}>
        <CardHeader
          selected={selected}
          handleSelected={handleSelected}
          getLabel={getLabel}
        />

        <Card.Body>
          <Container className={'px-2 text-normal mt-4'}>
            {(resultsState.results.length > 0) &&
              <Row className={'mb-0'}>
                <Col xs={10} >
                  <Row className={'doc-content mb-0 position-relative'}>
                    {metaFields[ABOVE_FOLD].map((elem: JSX.Element, index: any) =>
                      <Row className={'entry-row d-flex'} key={index}>{elem}</Row>)}
                  </Row>

                  <Collapse in={expanded}>
                    <div className={'doc-content mb-3'}>
                      <Row>
                        {metaFields[BELOW_FOLD].map((elem: JSX.Element, index: any) =>
                          <Row className={'entry-row d-flex'} key={index}>{elem}</Row>)}
                      </Row>
                    </div>
                  </Collapse>

                  <Row className={'expand'}>
                    <Col className={'mb-2 mx-0 px-0'} >
                      <LinkButton
                        label={expanded ? 'Less' : 'More'}
                        underline={true}
                        clickFunc={toggleExpanded}
                      />
                    </Col>
                  </Row>

                  {/* Tags  */}
                  <Row className={'doc-tags mb-3'}>
                    <Col xs={2}>
                      <span className={'me-4'}><b>Tags:</b></span>
                    </Col>

                    <Col xs={10} className={'tags-applied ps-2'}>
                      <Tags appliedTags={savedDocumentData?.tags}/>
                      <TagsModal appliedTags={savedDocumentData?.tags}/>
                    </Col>
                  </Row>

                  {/* Note */}
                  {userState.loggedIn &&
                    <Row className={'doc-note doc-content mt-3 mx-0 px-0'}>
                      <Col className={'px-0 text-nowrap'}>
                        <span className={'me-3 '}><b>Notes:</b></span>
                        <Note context={Constants.DOCUMENT}/>
                      </Col>
                    </Row>}
                </Col>

                {/* Preview Image */}
                <Col lg={2} className={'d-none d-lg-block h-100 center'}>
                  <MediaPreview context={Constants.SEARCH}/>
                </Col>

              </Row>}

            <Row className={'doc-view'}>
              <Col xs={12} className={'text-end'}>
                <LinkButton
                  label= "View Document"
                  clickFunc={handleViewDocumentClick}
                  linkArrow={true}
                  underline={true}
                />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </RecordsContext.Provider>
  )
}

export default DocumentCard;
