import {useEffect, useState} from "react";

/* Type, Constants, Utils */
import {TFilterOption, TFilterPivot} from "../../../types/search";
import {
  DATE_FACET_END_KEY,
  DATE_FACET_START_KEY,
  DEFAULT_DATE_YEARS_SOLR
} from "../../../constants";
import * as Constants from "../../../constants";

/* Redux */
import {useQuery} from "react-query";
import {ISearchState} from "../../../types/redux/search";

/* API */
import SearchService from "../../../api/search";

/* Hooks */
import useFilterOptions, {IUseFilterOptions} from "./useFilterOptions";

/* Helpers */
import * as QueryHelpers from "../../../api/legacy-helpers/query";

export interface IUseDateOptions {
  refetch():void;
  pivotOptions: TFilterOption[];
}

const useDateOptions = (pivotFilter:TFilterPivot, searchState: ISearchState): IUseDateOptions => {
  const [pivotOptions, setPivotOptions] = useState<TFilterOption[]>([]);
  const descendantsFilterOptions: IUseFilterOptions = useFilterOptions();

  const {data, isSuccess, refetch} = useQuery<any, Error>(
    [searchState + pivotFilter.id + '_years'],
    async ({signal}) => {
      const query: string = QueryHelpers.processQuery(searchState, false, false)[1];
      const urlParams = new URLSearchParams(query);
      const params: {[key:string]: string | string[]} = {...DEFAULT_DATE_YEARS_SOLR};
      params.q = urlParams.get(Constants.QUERY_KEY);
      if (pivotFilter.value === Constants.DATE_LESS_THAN_1950) {
        params[DATE_FACET_START_KEY] = Constants.DATE_FACET_INITIAL_DATE;
        params[DATE_FACET_END_KEY] = Constants.DATE_FACET_EARLY_THRESHOLD;
      } else {
        params[DATE_FACET_START_KEY] = pivotFilter.value
        params[DATE_FACET_END_KEY] = new Date(new Date(pivotFilter.value).setFullYear(new Date(pivotFilter.value).getFullYear() + 10)).toISOString();
      }
      return await SearchService.solrDateQuery(params, signal)
    },
    {
      staleTime: Infinity,
      enabled: false
    }
  );

  useEffect(()=>{
    if (isSuccess) {
      const facet_counts: any = data.facet_counts;
      if (data.facet_counts && facet_counts.facet_ranges && facet_counts.facet_ranges.dddate) {
        setPivotOptions(descendantsFilterOptions.facetFieldsOptions(pivotFilter, facet_counts.facet_ranges.dddate.counts) as TFilterOption[]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data])

  return {
    refetch,
    pivotOptions
  }
};

export default useDateOptions;
