import './styles.scss';
import React, {useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import {IViewerRecordContext, RecordsContext, ViewerRecordContext} from "../../../types/document";
import Utils from "../../../utils";

/* Components */
import {Button} from "react-bootstrap";
import useDownloadFile from "../../toolbars/components/download-dropdown/useDownloadFile";
import DownloadModal from "../../modal/download-modal";
import {IUserState} from "../../../types/redux/user";
import { useSelector} from "react-redux";
import {RootState} from "../../../redux";
import LoginPromptModal from "../../modal/login-prompt-modal";

const ALLOWED_MEDIA: string[] = ['text','image','pdf','zip'];

const ViewerDownload = ():JSX.Element => {
  /* context */
  const viewingRecord: any = useContext<IViewerRecordContext>(ViewerRecordContext).viewerRecord;

  /* redux */
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);

  /* state */
  const [pendingLogin, setPendingLogin] = useState<boolean>(false);
  const [loginPromptModal, setLoginPromptModal] = useState<boolean>(false);
  const [artifacts, setArtifacts] = useState(null);

  /* hooks */
  const {downloadFile, downloads} = useDownloadFile();

  /* variables */
  let allFilesTotalBytes: number = 0;

  const moveToFirstPos = (array: any[], mediaType: string): any[]=>{
    const pos: number = array.findIndex((item:any)=>{
      return item.mediaType === mediaType
    });
    if (pos !== -1) {
      const item: any = array.splice(pos, 1);
      array.unshift(item[0]);
    }
    return array;
  }

  useEffect(()=>{
    if (viewingRecord && viewingRecord.artifact) {
      let _artifacts: any[] = JSON.parse(viewingRecord.artifact);
      console.log(JSON.stringify(_artifacts))
      _artifacts = moveToFirstPos(_artifacts,"image/tiff");
      _artifacts = moveToFirstPos(_artifacts,"application/pdf");
      setArtifacts(_artifacts)
    }
  }, [viewingRecord])

  useEffect(()=>{
    if (pendingLogin && userState.loggedIn) {
      setPendingLogin(false);
      downloadFile(viewingRecord.id, `${viewingRecord.id}.zip`, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.loggedIn])

  return  (viewingRecord ?
    <RecordsContext.Provider value={{records:[viewingRecord]}}>
      <div className={'viewer-download px-2 mt-3'}>
        {artifacts &&
          <div className={'mt-2 d-grid'}>
            {artifacts.map((artifact:any, index:number) => {
             /* disallow video and audio files from downloads */
             const mediaType: string[] = artifact.mediaType.split('/');
             if (ALLOWED_MEDIA.indexOf(mediaType[0]) || ALLOWED_MEDIA.indexOf(mediaType[1])) {
               allFilesTotalBytes += artifact.size;
               return <Button
                 className={'rounded-1 w-100 my-1 py-2 border border-dark text-dark bg-transparent text-normal'}
                 key={index}
                 onClick={() => downloadFile(viewingRecord.id, artifact.name, false)}
               >
                 <i className="bi bi-download fst-normal me-2 bg-transparent"/>
                 <span>{artifact.name} - {Utils.convertBytes(artifact.size)}</span>
               </Button>
             }
             return <></>
           })}

          <Button
            className={'rounded-1 w-100 my-1 text-normal'}
            variant={'primary'}
            onClick={() => {
              if (userState.loggedIn) {
                downloadFile(viewingRecord.id, `${viewingRecord.id}.zip`, true)
              } else {
                setPendingLogin(true);
                setLoginPromptModal(true);
              }
            }}
          >
            <i className="bi bi-download fst-normal me-2 bg-transparent"/>
            <span>All Files (zip) - {Utils.convertBytes( String(allFilesTotalBytes))}</span>
          </Button>

          </div>
        }
      </div>

      {loginPromptModal && <LoginPromptModal
        title={'Download All Files'}
        show={loginPromptModal}
        onHide={()=>setLoginPromptModal(false)}/>}

      <DownloadModal
        downloads={downloads}
        show={downloads.length > 0}
      />
    </RecordsContext.Provider>
   : <></>
  )
}
export default ViewerDownload;
