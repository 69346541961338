import './styles.scss';

import React from 'react'

import {NavLink} from "react-router-dom";
import {Button} from "react-bootstrap";

type ButtonProps = {
  label?: string
  appearance?: 'default' | 'primary' | 'secondary' | 'none'
  el?: 'button' | 'link' | 'a'
  onClick?: () => void
  href?: string
  newTab?: boolean
  className?: string
  type?: 'submit' | 'button'
  disabled?: boolean
  invert?: boolean
}

export type CMSLinkType = {
  type?: 'custom' | 'reference'
  url?: string
  fields?: any
  newTab?: boolean
  reference?: {
    value: {
      id: string
      slug:string
      title: string
      industries: {
        id: string,
        title: string
      },
      categories: {
        id: string,
        title: string
      },
    }
    relationTo: 'pages'
  }
  label?: string
  appearance?: ButtonProps['appearance']
  children?: React.ReactNode
  className?: string
  invert?: ButtonProps['invert']
}

export const CMSLink: React.FC<CMSLinkType> = ({
  type,
  fields,
  url,
  newTab,
  reference,
  label,
  appearance,
  children,
  className,
  invert,
}) => {

  let href: string = '#';
  if ((type === 'reference') && (typeof reference?.value === 'object') && reference.value.id) {
    /* @ts-ignore */
    if (reference?.relationTo === 'media') {
      console.log('reference?.relationTo media')
      const {
        REACT_APP_CMS_HOST,
        REACT_APP_LOCALHOST,
        REACT_APP_LOCAL_CMS_HOST
      } = process.env;
      const domain: string | undefined = (window.location.host === REACT_APP_LOCALHOST) ? REACT_APP_LOCAL_CMS_HOST : REACT_APP_CMS_HOST;
      /* @ts-ignore */
      if (reference.value.url  !== undefined) {
        /* @ts-ignore */
        href=`${domain}${reference.value.url}`
      }

    } else {
      href = `${reference?.relationTo !== 'pages' ? `/${reference?.relationTo}/${reference?.value.id}` : ''}/${reference.value.industries.title.toLowerCase()}/${reference.value.categories.title.toLowerCase()}/${reference.value.id}/`
    }
  } else {
    href = url;
  }

  href = href.replace('/global', '')

  if (!href) return null;

  const addToBreadCrumb = (path: string)=>{
   /* alert('clicked')
    const parts: string[] = path.split('/');
    const label: string = parts[parts.length - 1].trim();
    console.log('add to Crumb on Link ' + label)
    dispatch(addCrumb({
      label: Utils.toTitleCase(label),
      href: path,
      level: 3,
      active: true
    }));*/
  }

  if (fields?.buttonLink === true) {
    return (
      <NavLink to={href || url} >
        <Button className={'link-btn bg-white text-black border-dark mb-4 text-start px-3 py-2 rounded-1'}>
          <span>{label}</span>
          <span className={'long-arrow-right'}/>
        </Button>
      </NavLink>
    )
  }

  const newTabProps = newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {}
  if (!appearance) {
    if (href || url ) {
      return (
          <NavLink onClick={()=>addToBreadCrumb(href || url)} {...newTabProps} to={href || url} className={'link ' + className}>
            {/* todo: jain - investigate why this created duplicate {label && label}*/}
            {children && children}
          </NavLink>
      )
    }
  }

  return (
      <NavLink className={'link'} {...newTabProps} to={href || url} >
        <span>{label}</span>
      </NavLink>
  )
}
