import './styles.scss';
import React, {useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import {IResultsState} from "../../../types/redux/result";
import {SelectedRecordsContext} from '../../../types/document';

/* Bootstrap */
import {Col, Container, Row} from "react-bootstrap";

/* Redux */
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";

/* Components */
import SelectResults from "../components/select-results";
import SortDropdown from "../components/sort-dropdown";
import Paging from "../components/search-paging";
import CardTools from "../card-tools";

const PageToolsTop = ():JSX.Element => {
  const resultState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);

  useEffect(()=>{
    /* Converts results selected list of ids to list of records */
    const records: any[] = resultState.selected.map(selected => {
      return resultState.results.find((record: any) => {
        return record.id === selected.id
      });
    })
    setSelectedRecords(records);
  }, [resultState.selected, resultState.results])

  return (
    <SelectedRecordsContext.Provider value={{records:selectedRecords}}>
      <Container className={'page-tools-top bg-light p-0 p-lg-2 border border-1'}>
        {/* Mobile */}
        <Row className={'d-lg-none w-100 m-0 py-2'}>
          <Col className={'d-flex justify-content-between m-0 px-2'}>
            {/* Sort by */}
            <SortDropdown context={Constants.PAGE}/>

            {/* Pagination */}
            <Paging
              previous={' '}
              showPageInput={true}
              next={' '}
            />
          </Col>
        </Row>

        <Row className={'d-lg-none border-top m-0 py-2'}>
          <Col className={'m-0 p-0 d-flex text-nowrap px-2'}>
            {/* Select Results */}
            {/*<SelectResults />*/}

            {/* Card(s) Tools */}
            <CardTools context={Constants.TOOLBAR}/>
          </Col>
        </Row>

        <Row className={'d-lg-none border-top m-0 py-2'}>
          <Col className={'m-0 p-0 d-flex text-nowrap px-2 '}>
            {/* Select Results */}
            <SelectResults />
          </Col>
        </Row>

        {/* desktop */}
        <Row className={'d-none d-lg-flex m-0'}>
          <Col className={'p-0 d-flex text-nowrap'}>
            {/* Select Results */}
            <SelectResults />

            {/* Card(s) Tools */}
            <CardTools context={Constants.TOOLBAR}/>
          </Col>

          <Col className={'d-flex justify-content-end mt-1 text-nowrap'}>
            {/* Sort by */}
            <SortDropdown context={Constants.PAGE}/>

            {/* Pagination */}
            <Paging
                previous={'Previous'}
                showPageInput={true}
                next={'Next'}
            />
          </Col>
        </Row>

      </Container>
    </SelectedRecordsContext.Provider>
  )
}
export default PageToolsTop;
