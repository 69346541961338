import './styles.scss';
import React, {Dispatch, useEffect} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../constants";
import {PageContext} from "../../types/app";

/* Bootstrap */
import {Container} from "react-bootstrap";
import {setIndustry} from "../../redux/slices/search-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";

/* Components */
import IndustryMenu from "../../components/industry-menu";
import ViewScrollContainer from "../../components/view/view-scroll-container";

/* hooks */
import useSearchReset from "../../hooks/useSearchReset";
import SearchInput from "../../components/search/search-input";

const Home = ():JSX.Element => {
  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();

  /* hooks */
  const searchReset: Function = useSearchReset();

  useEffect(()=>{
    searchReset();
    dispatch(setIndustry(Constants.ALL_INDUSTRIES))
    window.history.replaceState(null, '', window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={'home'}>
      <PageContext.Provider value={{context: Constants.HOME}}>
        <ViewScrollContainer className={'view-no-breadcrumb'}>

          <Container>
            <div className={'input-container w-75 m-auto my-4 '}>
              <h2 className={'text-center pt-3 mb-4'}>What are you looking for?</h2>
              <div className={'search-input-container shadow rounded border border-secondary'}>
                <SearchInput advSearch={false} minimal={true} fieldSelect={false}/>
              </div>
            </div>

            <IndustryMenu/>
          </Container>

        </ViewScrollContainer>
      </PageContext.Provider>
    </div>
  )
}

export default Home;
