/* Types, Constants, Utils */
import * as Constants from "../../constants";

/* Redux */
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ITagState, TSavedDocumentTag, UpdateTagNotesAction} from "../../types/redux/saved";

const initialState = ():ITagState => {
  return {
    loaded: false,
    tags: [],
    colorDiction: {},
    lastColorIndex: -1
  }
}

const createNewTag = (state: ITagState, tagData: TSavedDocumentTag):void => {
  const found: string | undefined = state.colorDiction[String(tagData.id)];
  if (found) {
    /* error case should never occur */
    throw new Error('Tag already exists')
  } else {
    let colorIndex: number = state.lastColorIndex + 1;
    colorIndex = (colorIndex === Constants.TAGS_COLORS.length) ? 0 : colorIndex;
    state.lastColorIndex = colorIndex;
    state.colorDiction[String(tagData.id)] = Constants.TAGS_COLORS[colorIndex];
    state.tags.unshift(tagData);
  }
}

export const tagsNotesSlice = createSlice({
  name: UpdateTagNotesAction,
  initialState: initialState,
  reducers: {
    addTags: (state: ITagState, action: PayloadAction<TSavedDocumentTag[]>) => {
      action.payload.forEach(tag => createNewTag(state, tag))
    },

    changeTagText: (state: ITagState, action: PayloadAction<{ id: string, text: string }>) => {
      const index: number = state.tags.findIndex((tag:TSavedDocumentTag)=>String(tag.id) === action.payload.id);
      if (index !== -1) {
        const tagsCn: TSavedDocumentTag[] = [...state.tags];
        tagsCn[index].text = action.payload.text;
        state.tags = tagsCn;
      }
    },

    setLoadedTags: (state: ITagState, action: PayloadAction<any[]>) => {
      const previousTagIds: number[] = state.tags.map(tag=>tag.id);

      /* create new tags */

      action.payload.forEach((tag: any)=>{
        if (state.colorDiction[String(tag.id)]) {
          /* is reloading and tag already exist */
          previousTagIds.splice(previousTagIds.indexOf(tag.id),1)
        } else {
          createNewTag(state, tag)
        }
      });

      /* if reloading, prune stale tags */
      const tagsClone: TSavedDocumentTag[] = [...state.tags];
      previousTagIds.forEach(tagId=>{
        const index: number = tagsClone.findIndex(tag=>tag.id === tagId);
        tagsClone.splice(index,1)
      });
      state.tags = tagsClone;

      state.loaded = true;
    },

    reloadTags: (state: ITagState) => {
      state.loaded = false;
    },

    clearTags: (state: ITagState) => {
      state = {...initialState()};
      return state;
    }
  }
});

export const {
  clearTags,
  changeTagText,
  reloadTags,
  setLoadedTags,
  addTags
} = tagsNotesSlice.actions;

export default tagsNotesSlice.reducer;
