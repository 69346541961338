import Utils from "../utils";
import {SAVED_BIBLIOGRAPHY, SAVED_DOCUMENTS, SAVED_SEARCHES, SEARCH_HISTORY} from "./app";
import * as Constants from "./search";
import {TFilterOption} from "../types/search";

export const SAVED_BIBLIOGRAPHY_KEY: string = 'biblioId';
export const SAVED_DOCUMENT_KEY: string     = 'documentId';

export const MY_LIBRARY_SECTION_KEY: string = 'section';
export const MY_LIBRARY_MENU_ITEMS: any[] = [
  {
    label: Utils.toTitleCase(SAVED_DOCUMENTS),
    section:SAVED_DOCUMENTS
  },
  {
    label: Utils.toTitleCase(SAVED_BIBLIOGRAPHY),
    section: SAVED_BIBLIOGRAPHY
  },
  {
    label: Utils.toTitleCase(SAVED_SEARCHES),
    section: SAVED_SEARCHES
  },
  {
    label: Utils.toTitleCase(SEARCH_HISTORY),
    section: SEARCH_HISTORY
  }
];

export const SAVED_SEARCHES_API_KEY: string   = 'savedSearches';
export const MY_LIBRARY_DEFAULT_SORT: string  = 'mldcdesc';
export const SESSION_KEY_TAG_COLOR: string    = 'tag_clr'
export const TAGS: string                     = 'tags';
export const TAGS_COLORS: string[]  = ['#BDE1E7','#FFFED9', '#EFFEC7', '#F8E5FD', '#FAE2D3', '#E8E7FD', '#F7D1E8', '#D4E5FD', '#FCECF5' ];
export const APPLIED_TAGS: string   = 'applied-tags';
export const REMOVE_TAG: string     = 'remove-tag-add-remove-edit';
export const EDIT_TAG: string       = 'edit-tag-add-remove-edit';
export const REMOVE_ALL: string     = 'remove-all';
export const REMOVE: string         = 'remove';
export const ADD: string            = 'add';
export const APPLY_ALL: string      = 'apply-all';
export const APPLY_ONLY: string     = 'apply-only';
export const SELECT_ALL: string     = 'select-all';

export const DEFAULT_FILTER_OPTION: TFilterOption  = {
  id: '',
  label: '',
  fq_group:'',
  field: 'none',
  value: '',
  count: 0,
  invert: false,
  industry: undefined,
  add: Constants.APPEND
}
