import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import {TFilterOption, TFilterPivot} from "../../../types/search";
import * as Constants from "../../../constants";

/* Bootstrap */
import {Accordion} from "react-bootstrap";
import Utils from "../../../utils";
import FiltersCheck from "../filters-check";
import Spinner from "../../util/spinner";
import useDateOptions, {IUseDateOptions} from "../hooks/useDateOptions";
import {ISearchState} from "../../../types/redux/search";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {addRemoveSelectedFilters} from "../../../redux/slices/search-slice";

interface IProps {
  filterOptionElems(options: TFilterOption[], onHandleChange: any): JSX.Element;
  pivotFilter: TFilterPivot;
}

const FiltersCascade = (props: IProps):JSX.Element => {
  const {
    filterOptionElems,
    pivotFilter
  } = props;

  /* redux */
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const dispatch:Dispatch<AnyAction> = useDispatch();

  /* state */
  const [activeKeys, setActiveKeys] = useState<any>([]);
  const [filter, setFilter] = useState<TFilterPivot>({...pivotFilter});
  const [loading, setLoading] = useState<boolean>(false);

  /* hooks */
  const dateOptions: IUseDateOptions = useDateOptions(pivotFilter, searchState);

  /* @ts-ignore */
  const [selectAllOption] = useState<TFilterOption>({ // setSelectAllOption
    id: pivotFilter.id + '-' + Constants.SELECT_ALL,
    label: Utils.toTitleCase(Constants.SELECT_ALL),
    value: pivotFilter.value,
    field: pivotFilter.field
  })

  /* Handle date years dynamic loading results */
  useEffect(()=>{
    if (dateOptions.pivotOptions.length > 0){
      const filterCln: TFilterPivot = {...filter};
      filterCln.options = dateOptions.pivotOptions;
      setFilter(filterCln);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOptions.pivotOptions])

  useEffect(()=> {
    if (loading) {
      setLoading(false);
      handleToggleItem(filter.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.options ])

  const handleSelectSubordinate = (selectedOption: TFilterOption): void =>{
    let ids: string[] = [];
    if (searchState.selected_filters_ids.indexOf(selectedOption.id) !== -1) {
      ids = [selectAllOption.id, selectedOption.id];
      dispatch(addRemoveSelectedFilters({ids:ids, method:Constants.REMOVE}))
    } else {
      const selectedOptions: string[] = filter.options.map(fo=> fo.id);
      if (selectedOptions.length === filter.options.length){
        handleSelectAll(selectedOption)
      } else {
        dispatch(addRemoveSelectedFilters({ids:[selectedOption.id], method:Constants.ADD}))
      }
    }
  }

  const handleSelectAll = (selectedOption: TFilterOption): void =>{
    let ids: string[] = [];
    const selectedOptionsIds: string[] = filter.options.map(f=>f.id);
    ids = ids.concat(selectedOptionsIds)
    ids.push(selectAllOption.id)

    if (searchState.selected_filters_ids.indexOf(selectedOption.id) !== -1) {
      dispatch(addRemoveSelectedFilters({ids:ids, method:Constants.REMOVE}))
    } else {
      dispatch(addRemoveSelectedFilters({ids:ids, method:Constants.ADD}))
    }
  }

  const handleToggleItem = (key: string):void => {
    if (filter.options.length === 0) {
      /* currently on date subordinates are loaded dynamically */
      setLoading(true)
      dateOptions.refetch();
    } else {
      const index = activeKeys.indexOf(key);
      if (index > -1) {
        activeKeys.splice(index, 1);
        setActiveKeys([...activeKeys]);
      } else {
        setActiveKeys(activeKeys.concat(key));
      }
    }
  }

  return (
    <div className={'filters-cascade'}>
      <Accordion activeKey={activeKeys}>
        <Accordion.Item
          key={filter.id + '_check'}
          eventKey={filter.id}
          className={'mb-3 ms-1'}
          onClick={()=>handleToggleItem(filter.id)}
        >
          <Accordion.Header className={loading ? 'loading' : ''}>
            <span role={'button'} >
              <b>{filter.label}</b>
              <span>&nbsp;({Utils.formatNumber(filter.count)})</span>
            </span>

            {loading &&
              <Spinner size={Constants.TINY}/>}
          </Accordion.Header>

          <Accordion.Body className={'m-0 p-0 mt-2 mx-3 '}>

            {/* select all */}
            <FiltersCheck
              key={selectAllOption.id}
              onHandleChange={(value)=>handleSelectAll(value)}
              type={'checkbox'}
              filterOption={selectAllOption}/>

            {/* subordinate options */}
            {filterOptionElems(filter.options, handleSelectSubordinate)}

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}
export default FiltersCascade;
