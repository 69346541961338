import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";

/* Bootstrap */
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {
  addToSavedHistory,
  addToSavedSearch,
  removeFromSavedHistory,
  removeFromSavedSearches
} from "../../../../redux/slices/saved-slice";
import {ISearchEntry} from "../../../../types/redux/search";
import {RootState} from "../../../../redux";
import {ISavedState} from "../../../../types/redux/saved";
import {addToast} from "../../../../redux/slices/toast-slice";
import {IUserState} from "../../../../types/redux/user";

/* Api */
import SavedService from "../../../../api/saved";

/* Components */
import LoginPromptModal from "../../../modal/login-prompt-modal";
import Utils from "../../../../utils";

const SearchSave = ():JSX.Element => {
  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const savedState: ISavedState = useSelector<RootState, ISavedState>(state => state.saved);
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);

  /* state*/
  const [pendingLogin, setPendingLogin] = useState<boolean>(false);
  const [loginPromptModal, setLoginPromptModal] = useState<boolean>(false);
  const [savedSearch, setSaveSearch] = useState<ISearchEntry | null>(null);

  useEffect(()=>{
    if (pendingLogin && userState.loggedIn) {
      setPendingLogin(false);
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.loggedIn])

  const handleError = (e: any): void =>{
    if (e.status === 403) {
      /* not logged in */
      setLoginPromptModal(true);
    } else {
      // todo: jain - handle generic errors globally
      dispatch(addToast({
        bg: 'bg-danger',
        msg: Constants.ERROR_NOTIFICATION,
        id: -1
      }))
    }
  }

  const savedToast = (type:string) => {
    dispatch(addToast({
      bg: 'bg-success',
      msg: Utils.toTitleCase(type) + ' saved.',
      id: -1
    }))
  }

  const handleSave = ():void => {
    if (!userState.loggedIn) {
      setPendingLogin(true);
      setLoginPromptModal(true);
    } else {
      if (savedSearch) {
        SavedService.deleteSearches([savedSearch.id]).then(response =>{
          setSaveSearch(null)
          dispatch(removeFromSavedSearches([String(savedSearch.id)]));
          dispatch(removeFromSavedHistory([savedSearch]));
        }).catch(e => handleError(e))
      } else {
        const searchData: ISearchEntry = {...savedState.history[0]};
        SavedService.saveSearches([searchData]).then(response =>{
         setSaveSearch(searchData)
         dispatch(addToSavedSearch(response[0]));
         dispatch(addToSavedHistory(response));
          savedToast(Constants.SEARCH)
        }).catch(e => handleError(e));
      }
    }
  }

  return (
    <div className={'search-save'}>
      <OverlayTrigger overlay={
        <Tooltip className={'mb-3'}>
          {savedSearch ? <span>Remove from saved searches</span> : <span>Save search</span>}
        </Tooltip>
      }>
        <span> {/* enable bs tooltip to display when button is disabled */}
          <Button
            className={'btn-transparent border-0 rounded-0 h-100 px-2 me-1 text-black'}
            onClick={()=>handleSave()}
          >
            {savedSearch ? <i className={'bi bi-bookmark-x'}/> : <i className={'bi bi-bookmark'}/>}
          </Button>
        </span>
      </OverlayTrigger>

      {loginPromptModal && <LoginPromptModal
        title={'Save Searches'}
        show={loginPromptModal}
        onHide={()=>setLoginPromptModal(false)}/>}
    </div>
  )
}

export default SearchSave;
