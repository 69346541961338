import './styles.scss';
import React, {Dispatch, useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";

/* Components */
import ViewerPDF from "../viewer-pdf";
import ViewerMedia from "../viewer-media";
import ViewerToast from "../viewer-toast";

import {IFileAttributes, IViewerRecordContext, ViewerRecordContext} from "../../../types/document";
import Utils from "../../../utils";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {setContentView} from "../../../redux/slices/viewer-slice";
import {IViewerState} from "../../../types/redux/viewer";
import {RootState} from "../../../redux";

import axios from "axios";
import jsPDF from "jspdf";

interface IProps {
  fileAttributes: IFileAttributes;
  viewerRecord: any;
}

const ViewerDocument = (props: IProps):JSX.Element => {
  const {
    fileAttributes,
    viewerRecord
  } = props;

  const viewingRecordContext: IViewerRecordContext = useContext<IViewerRecordContext>(ViewerRecordContext);
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);
  const [nativeRelatedFileProps, setNativeRelatedFileProps] =
    useState<{url: string, fileResolved:boolean, id: string}>({url: null, fileResolved: false, id: null})
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const link = (): string => {
    let url: string = '';
    if (viewerRecord.artifact) {
      const artifacts: any  = JSON.parse(viewerRecord.artifact);
      url = Utils.getBucketPath(viewerRecord.id) + '/' + artifacts[0].name;
    }
    return url;
  }

  const relatedDocument =  (): { id: string, type: string } => {
    const artifacts: any  = JSON.parse(viewerRecord.artifact);
    let id: string;
    let type: string;
    for (let i = 0; i < artifacts.length; i++) {
      const mediaType = artifacts[i].mediaType.split('/').slice(-1).pop();
      if (mediaType === Constants.PDF) {
        /* if has ocr and pdf give pdf priority */
        id = artifacts[i].name.substring(0,artifacts[i].name.length - 4);
        type = Constants.PDF;
        break;
      } else if ((mediaType === Constants.OCR) || (mediaType=== Constants.PLAIN)) {
        id = artifacts[i].name.substring(0,artifacts[i].name.length - 4);
        type = Constants.OCR;
      }
    }
    return {id, type};
  }

  useEffect(()=>{
    let url: string | null = null;
    let id: string | null = null;

    if (fileAttributes.type === Constants.ZIP) {
      const relatedRecord: {id:string, type:string} = relatedDocument();
      id = relatedRecord.id;

      if (id) {
        dispatch(setContentView(relatedRecord.type));

        /* handle zip / native with pdf or ocr text */
        if ((relatedRecord.type === Constants.OCR) || (relatedRecord.type === Constants.PLAIN)) {
          axios.get(
            Utils.getBucketPath(id) + '/' + id +'.ocr',
            {responseType: 'text'}
          ).then((response: any) => {
            let pdf: jsPDF = new jsPDF();
            pdf.text(response.data, 10, 10);
            let blobPDF: Blob = new Blob([pdf.output('blob')], {type: 'application/pdf'});
            url = URL.createObjectURL(blobPDF);
            setNativeRelatedFileProps({url, fileResolved: true, id: id}) // async
          })
        }
      }
    }

    setNativeRelatedFileProps({fileResolved: true, url, id})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const viewerCmp =   (): JSX.Element => {
    if (!fileAttributes) return <></>

    switch (fileAttributes.type) {
      case Constants.PDF:
        return <ViewerPDF id={viewingRecordContext.viewerRecord.id}/>

      case Constants.WEBSITE:
      case Constants.AUDIO:
      case Constants.VIDEO:
        return <ViewerMedia/>;

      case Constants.ZIP:
        // see useEffect above for text & pdf fetches
        return (
          <>
            {nativeRelatedFileProps.fileResolved &&
              nativeRelatedFileProps.id &&
                <div className={'native-toast'}>
                  <ViewerToast
                    variant={'success'}
                    icon={'bi-file-earmark-arrow-down'}
                    content={<span><a href={link()}>Download</a>  the source file(s) to view this document.</span>}
                  />

                  <div className={'zip-pdf'}>
                    <ViewerPDF
                      id = {nativeRelatedFileProps.id}
                      url = {nativeRelatedFileProps.url}
                    />
                  </div>
                </div>}
          </>)

      case Constants.PRIVILEGED:
      case Constants.COPYRIGHT:
      case Constants.CONFIDENTIAL:
        if (viewingRecordContext.publicRecord) {
          //eg. tmlg0155 - previous confidential and public version available
          return (
            <div className={'restricted-toast'}>
              <ViewerToast
                variant={'success'}
                content={
                  <span>
                    <p>This document was previously designated as confidential.
                      Document Tools for both public and restricted versions are available.</p>
                    <br></br><br></br>
                    <p>Learn more about <a href={'/help/privileged-and-confidential-documents'}>privileged and confidential documents</a>.
                    </p>
                  </span>}
              />

              {/* display public record */}
              {viewerState.viewPublicRecord &&
                <ViewerPDF id={viewingRecordContext.publicRecord.id}/>}
            </div>
          )
        } else {
          // eg. prwj0233 - confidential
          return (<ViewerToast
            variant={'success'}
            content={<div className={''}>This document is designated as confidential, and only metadata is available.&nbsp;&nbsp;
              <a href={'/help/privileged-and-confidential-documents'} >Learn more about privileged and confidential documents.</a></div>}
          />)
        }
    }
  }

  return (
    <div className={'viewer-document'}>
      {viewerCmp()}
    </div>
  )
}
export default ViewerDocument;
