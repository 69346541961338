import './styles.scss';
import React, {Dispatch, useState} from "react";

/* Types, Constants, Utils */

/* Bootstrap */
import Form from 'react-bootstrap/Form';
import {addToast} from "../../../redux/slices/toast-slice";

/* Api */
import LoginRegistrationService from "../../../api/login-registration";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import PasswordField from "../../fields-buttons/password-field";

/* hooks */
import useLogin from "../hooks/useLogin";

interface IProps {
  context: string;
  setRegistering(value: boolean): void;
}

const LoginRegistration = (props: IProps):JSX.Element => {
  const {
    context
  } = props;

  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();

  /* state */
  const [name, setName] = useState<string | null>(null);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [newsletter, setNewsletter] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<any[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  /* hooks */
  const login: Function = useLogin();

  const handleLogin = async(): Promise<any> => {
    const success: boolean = await login(email, password, 'on', context);
    if (success) {
      dispatch(addToast({
        bg: 'bg-success',
        msg: "You are now registered and logged in.",
        id: -1
      }))
    } else {
      setErrorMessage('An error occurred.');
    }
  }

  const handleKeydown =  (e: React.KeyboardEvent):void=>{
    if (e.code === 'Enter') {
      e.preventDefault();
    }
  }

  const handleSubmit = async (event:any): Promise<any> =>{
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity()) {
      LoginRegistrationService.register({
        name: name,
        email: email,
        password: password
      }).then(response => {
        handleLogin();
      }).catch(e =>{
        setValidated(false);
        switch (e.response.status) {
          case 400:
            setFieldErrors(e.response.data.errors);
            break;

          case 422:
            setErrorMessage(e.response.data.message);
            break;

          default:
            setErrorMessage('Sorry, an error occurred.');
        }
      })
    }
    setValidated(true);
  }

  const getErrors = (field: string): JSX.Element => {
    if (!fieldErrors) {
      return <></>
    }

    const errors: any[] = fieldErrors.filter( e => (e.field === field) );
    return <div>
      {errors.map(error=>{
        return <p className={'text-danger my-2'}>{error.message}</p>
      })}
    </div>
  }

  return (
    <div className={'registration-cmp'}>
      <h2 className={'font-bold'}>Create an account</h2>
      <h5 className={'my-3 font-light text-secondary'}>Create your Industry Documents Library account</h5>

      <Form
        noValidate
        validated={validated}
        className={'registration-form mt-4'}
        onSubmit={handleSubmit}
      >
        {/* name */}
        <Form.Group
          className={'mt-3'}
          controlId={'RegistrationForm.ControlName'}
        >
          <Form.Label className={'mb-1 font-light text-secondary'}>Name</Form.Label>
          <Form.Control
            type={'text'}
            value={name ? name : ''}
            onKeyDown={e=>handleKeydown(e)}
            onChange={e=>setName(e.target.value)}
          />
          <Form.Control.Feedback type={'invalid'}>
            Please enter a valid name.
          </Form.Control.Feedback>
        </Form.Group>

        {/* email */}
        <Form.Group
          className={'mt-3'}
          controlId={'RegistrationForm.ControlEmail'}
        >
          <Form.Label className={'mb-1 font-light text-secondary'}>Email<sup>*</sup></Form.Label>
          <Form.Control
            type={'email'}
            value={email}
            required
            onKeyDown={e=>handleKeydown(e)}
            onChange={e=>setEmail(e.target.value)}
          />
          <Form.Control.Feedback type={'invalid'}>
            Please enter a valid email.
          </Form.Control.Feedback>
        </Form.Group>

        {fieldErrors && getErrors('email')}

        {/* password */}
        <Form.Group
          className={'mt-3'}
          controlId={'RegistrationForm.ControlPassword'}
        >

          <PasswordField
            label={'Password'}
            setPassword={setPassword}
            password={password}
            handleKeydown={handleKeydown}
            isInvalid={()=>false}
            errorMessage={'A password at least 5 characters long is required.'}
          />
        </Form.Group>

        {fieldErrors && getErrors('password')}

        <div className={'mt-4 d-flex flex-column'}>
          <p className={'mb-0'}><b>Sign me up for</b></p>
          <Form.Group>
            <Form.Check
              className={"form-check mt-1 mb-3 float-start small"}
              type={'checkbox'}
              checked={newsletter}
              onChange={e=>setNewsletter(e.target.checked)}
              label={'Industry Documents Library Newsletter'}
            />
          </Form.Group>
          <p className={'text-smaller text-italic mt-0'}>Email newsletters are sent approximately once a month. We promise not to share your information.</p>
        </div>

        {/* Submission error ( non validation error )*/}
        {errorMessage &&
          <p className={'text-danger'}>{errorMessage}</p>}

        {/* Submit button */}
        <button
          className={'button-rounded w-100 py-3 mt-2'}
          type={'submit'}
        >
          <h6 className={'m-0'}>Create an account</h6>
        </button>

      </Form>
    </div>
  )
}
export default LoginRegistration;
