import './styles.scss';
import React, {Dispatch, useContext} from "react";
import {useNavigate} from "react-router-dom";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import Utils from "../../../utils";
import DocumentUtils from "../../../utils/document-utils";
import {IFileAttributes} from "../../../types/document";
import {RecordsContext, IRecordsContext} from "../../../types/document";

/* Bootstrap */
import {Button} from "react-bootstrap";

/* Redux */
import {setInitialSelection, setSelectedResults} from "../../../redux/slices/result-slice";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";

/* Hooks */
import {useSearchParams} from "../../../hooks";

interface IProps {
  context: string
}

const MediaPreview = (props: IProps):JSX.Element => {
  const {
    context
  } = props;

  const record: any = useContext<IRecordsContext>(RecordsContext).records[0];
  const fileAttributes: IFileAttributes = DocumentUtils.getFileAttributes(record);
  const {currentSearchParams} = useSearchParams();
  const navigate: NavigateFunction = useNavigate();
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const handleClick = (e:any = null): void => {
    if (e){
      e.preventDefault();
      e.stopPropagation();
    }
    dispatch(setInitialSelection(record.id));
    dispatch(setSelectedResults([record.id]));
    const url: string = '/' + Utils.convertToKeyOrId(record.industry) + '/documents/' + Constants.VIEWER + '?' + Constants.INITIAL_ID + '=' + record.id + '&' + Constants.ID + '=' + record.id + '&' + currentSearchParams().toString();
    navigate(url);
  }

  const pdfPreview = ():JSX.Element => {
    const id:string = record.id;
    const thumbURL:string =  Utils.getBucketPath(id) + '/' + id +'_thumb.png';
    const url: string = '/' + Utils.convertToKeyOrId(record.industry) + '/documents/' + Constants.VIEWER + '?' + Constants.INITIAL_ID + '=' + record.id + '&' + Constants.ID + '=' + record.id + '&' + currentSearchParams().toString();
    return (<>
      {(context === Constants.SEARCH) &&
        <div className={'thumbnail d-flex justify-content-end'}>
          <div className={'btn-container mt-1 d-flex flex-column'}>
            <Button className={'preview border-grey-light btn bg-white rounded-1'} onClick={e=>handleClick(e)}>
              <a href={url} >
                <img
                  src={thumbURL}
                  className="figure-img img-fluid mb-0" alt="..."
                />
              </a>
            </Button>

            {record.pg &&
                <Button
                  className={'btn-link text-xsmall text-secondary'}
                  onClick={e=>handleClick(e)}
                >
                  <a className={'btn-link '} href={url}>
                    <b> PDF - <br></br>{record.pg}{' Pages'} </b>
                  </a>
                </Button>
             }
          </div>
        </div>
        }

      {(context === Constants.VIEWER) &&
        <>
          <div className={'mt-2 preview border-grey text-center rounded'}>
            <img
              src={thumbURL}
              className={'figure-img img-fluid mb-0 p-1'} alt="..."
            />
          </div>
          {record.pg &&
            <div className={'mt-1 text-xsmall'}>
              <p className={'p-0 m-0'}><b>{'PDF '}</b></p>
              <p className={'p-0 m-0 text-nowrap'}><b>{Utils.formatNumber(record.pg)}&nbsp;{'Pages'}</b></p>
            </div>
            }
        </>}
      </>)
  }

  const getThumbnail = (icon: string, type: string): JSX.Element => {

    if (context === Constants.SEARCH) {
      return <>
        <div className={'preview btn btn-primary text-center p-0 border-grey-light bg-white rounded-1'} onClick={()=>handleClick()}>
          <div className={`${type} rounded border p-3 p-lg-4`}>
            <i className={`bi ${icon} display-1 text-black`}/>
            <p className={"caption text-center text-black mb-0"}><b>{fileAttributes.type?.toUpperCase()}</b></p>
          </div>
        </div>
        {fileAttributes.size && <p className="mt-2">{fileAttributes.size}</p>}
      </>
    } else {
      return <>
        <div className={'preview viewer btn btn-primary text-center p-0 border-grey-light bg-white rounded-1'} onClick={()=>handleClick()}>
          <div className={`${type} rounded border p-2`}>
            <i className={`bi ${icon} display-1 text-black `}/>
            <p className={"caption text-center text-black mb-0"}><b>{fileAttributes.type?.toUpperCase()}</b></p>
          </div>
        </div>
        {fileAttributes.size && <p className="mt-2">{fileAttributes.size}</p>}
      </>
    }
  }

  const mediaMarkup = ():JSX.Element => {
    let block: JSX.Element = <></>;
    switch (fileAttributes.type) {
      case Constants.PDF:
        block = pdfPreview();
        break;

      case Constants.ZIP:
        block = getThumbnail('bi-file-zip', fileAttributes.type)
        break;

      case Constants.VIDEO:
        block = getThumbnail('bi-camera-video', fileAttributes.type)
        break;

      case Constants.AUDIO:
        block = getThumbnail('bi-volume-up', fileAttributes.type)
        break;

      case Constants.WEBSITE:
        block = getThumbnail('bi-globe2', fileAttributes.type)
        break;

      case Constants.CONFIDENTIAL:
      case Constants.PRIVILEGED:
      case Constants.COPYRIGHT:
        block = getThumbnail('bi-lock', fileAttributes.type)
        break;
    }

    return block
  }

  return <div className="media-preview h-100 text-center">
    {mediaMarkup()}
  </div>
}

export default MediaPreview;
