import {Context, createContext} from "react";

export type TFilterOption = {
  id: string;
  count: number;
  label: string;
  fq_group: string;
  value: string;
  field: string;
  invert: boolean;
  add: string;
  industry: string;
}

export type TFilterPivot = {
  id: string;
  label: string;
  value: string;
  field: string;
  fq_group: string;
  count: number;
  options: TFilterOption[];
}

export interface IFilterDB {
  documents: TFilter[];
  advance_search: TFilter[];
  tags:TFilter[];
}

export type TFilter = {
  id: string;
  info: {
    heading: string;
    value: string;
  } | undefined;
  heading: string;
  type: string;
  initially_opened: boolean;
  expanded: boolean;
  fq_group: string;
  facet_field: string;
  facet_pivot: string;
  facet_ranges: string;
  pivot_options: TFilterPivot[];
  pivot_expandable: boolean;
  options: TFilterOption[];
}

export type TSort = {
  id: string;
  label: string;
  value: string;
}

export type TSorts = {
  industry: {
    documents:TSort[],
    bibliography:TSort[]
  };
  "my-library": TSort[];
}

export type ISearchStateResultMeta = {
  num_found: number
}

export type IConstraintDropdown = {
  line: number;
  updateConstraints(line: number, field: string, value: string | null): void;
}
export const ConstraintDropdownContext: Context<IConstraintDropdown> = createContext<IConstraintDropdown>({line:0, updateConstraints: ()=>{}})
