import './styles.scss';
import React, {Dispatch, useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import Utils from "../../../../utils";
import {IRecordsContext, SelectedRecordsContext} from "../../../../types/document";

/* Bootstrap */
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {
  addToSavedBibliographies,
  addToSavedDocuments, addToSavedHistory, addToSavedSearch, removeFromSavedBibliographies,
  removeFromSavedDocuments, removeFromSavedHistory, removeFromSavedSearches
} from "../../../../redux/slices/saved-slice"
import {ISavedState, TSavedBibliography, TSavedDocument} from "../../../../types/redux/saved";
import {RootState} from "../../../../redux";
import {IUserState} from "../../../../types/redux/user";
import {addToast} from "../../../../redux/slices/toast-slice";

/* Api */
import SavedService from "../../../../api/saved";

/* Components */
import LoginPromptModal from "../../../modal/login-prompt-modal";
import {
  clearSelectedResults,
  removeFromResults,
  removeFromSelectedResults,
  updateSearchResult
} from "../../../../redux/slices/result-slice";
import {useCardContext} from "../../../../hooks";
import ConfirmModal from "../../../modal/confirm-modal";
import {reloadTags} from "../../../../redux/slices/tags-notes-slice";
import {IPageContext, PageContext} from "../../../../types/app";
import {IMyLibraryState} from "../../../../types/redux/my-library";

interface IProps {
  context: string
}

const Save = (props: IProps):JSX.Element => {
  const {
    context
  } = props;

  /* state */
  const [pendingLogin, setPendingLogin] = useState<boolean>(false);

  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const savedState: ISavedState = useSelector<RootState, ISavedState>(state => state.saved);
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);
  const myLibraryState: IMyLibraryState = useSelector<RootState, IMyLibraryState>(state => state.my_library);

  /* context */
  const cardContext: string = useCardContext().context;
  const pageContext: string = useContext<IPageContext>(PageContext).context;
  const selectedRecords: any[] = useContext<IRecordsContext>(SelectedRecordsContext).records;
  //const selectedRecords: ISelected[] = useSelector<RootState, IResultsState>(state => state.result).selected;

  /*state*/
  const [saved, setSaved] = useState<boolean>(false);
  const [loginPromptModal, setLoginPromptModal] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  useEffect(()=>{
    if (pendingLogin && userState.loggedIn) {
      setPendingLogin(false);
      handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.loggedIn])

  useEffect(()=>{
    const isSaved = (): boolean => {
      /*
      * If any of the selected records are not saved then the option to save is available
      * If all the selected records are saved then the option to save is unavailable
      */
      let unsavedFound: boolean = false;
      let savedIds: (string | number)[] = [];
      switch (cardContext) {
        case Constants.DOCUMENT:
          savedIds = savedState.documents.map((document: TSavedDocument) => document.documentId);
          break;

        case Constants.BIBLIOGRAPHY:
          savedIds = savedState.bibliographies.map(bibliography => bibliography.biblioId);
          break;

        case Constants.SEARCH:
          savedIds = savedState.searches.map(searches => searches.id);
          break;
      }

      if (savedIds) {
        if (savedIds.length === 0) {
          /* special case, none found so show "Save" on Page tools */
          unsavedFound = true;
        } else {
          /* determine if there is an unsaved record in a multiple selection */
          for (let i=0; i<selectedRecords.length; i++) {
            if (savedIds.indexOf(selectedRecords[i].id) === -1) {
              unsavedFound = true;
              break;
            }
          }
        }
      }

      return !unsavedFound
    }
    setSaved((selectedRecords.length > 0)  && isSaved())
  }, [savedState, selectedRecords, cardContext])

  const handleError = (e: any) => {
    if ((e.status === 403) || (e.response && ((e.response.status === 403) || (e.response.status === 401)))){
      /* not logged in */
      setPendingLogin(true);
      setLoginPromptModal(true);
    } else {
      dispatch(addToast({
        bg: 'bg-danger',
        msg: Constants.ERROR_NOTIFICATION,
        id: -1
      }))
    }
  }

  /* given a list of records, returns subset of which that are saved - invert give records not saved */
  const savedOrUnsavedIds = (selected: any[], saved: any[], key: string, invert: boolean = false): number[] => {
    const savedRecordsIds: number[] = [];
    for (let i=0; i<selected.length; i++) {
      const savedRecord: any = saved.find((savedRecord: any)=>{
        return savedRecord[key] === selected[i].id; /* id: document id in solr eg: 'qfkk0281' */
      })

      if (!invert) {
        /* subset of saved */
        if (savedRecord) {
          savedRecordsIds.push(savedRecord.id); /* id: saved record id issued by backend of type number - eg: 7   */
        }
      } else {
        /* subset of unsaved */
        if (!savedRecord ) {
          savedRecordsIds.push(selected[i].id);
        }
      }
    }

    return savedRecordsIds;
  }

  const confirmUnsaveDocument = ():void => {
    let hasNotesOrTag: boolean = false;
    for ( let i = 0; i<selectedRecords.length; i++) {
      const savedRecord: TSavedDocument | undefined = savedState.documents.find(document => document.documentId === selectedRecords[i].id);
      if (savedRecord) {
        if ((savedRecord.tags.length > 0) || (savedRecord.notes && (savedRecord.notes.length > 0))) {
          hasNotesOrTag = true;
          break;
        }
      }
    }
    if (hasNotesOrTag) {
      setConfirmModalOpen(true)
    } else {
      unSaveDocuments()
    }
  }

  const recordIdsListForRemoval = ():string[] => {
    const selectedRecordIds: string[] = selectedRecords.map((record:any) => record.id);
    if ((pageContext === Constants.MY_LIBRARY) && (myLibraryState.section !== Constants.SEARCH_HISTORY)) {
      /* if on my library page, maintain the results rather than hitting solr */
      dispatch(removeFromSelectedResults(selectedRecordIds));
      dispatch(removeFromResults(selectedRecordIds));
    }
    return selectedRecordIds;
  }

  const savedToast = (type:string) => {
    dispatch(addToast({
      bg: 'bg-success',
      msg: Utils.toTitleCase(type) + ' saved.',
      id: -1
    }))
  }

  const unSaveDocuments = (): void => {
    const savedRecordIds: number[] = savedOrUnsavedIds(selectedRecords, savedState.documents, Constants.SAVED_DOCUMENT_KEY); /* save id issued by backend*/
    SavedService.deleteDocuments(savedRecordIds).then(() =>{
      dispatch(clearSelectedResults())
      dispatch(removeFromSavedDocuments(recordIdsListForRemoval()));
      dispatch(reloadTags()); // re-fetch tag list, if no document referencing a tag then it no longer exists
    }).catch(e => handleError(e))
  }

  const saveDocuments = ():void =>{
    /* bulk save --> multiple selection, record save --> single selection */
    const unSavedRecordIds: number[] = savedOrUnsavedIds(selectedRecords, savedState.documents, Constants.SAVED_DOCUMENT_KEY, true);
    const recordsToSave: TSavedDocument[] = unSavedRecordIds.map((id: any) => {
      return {
        dateCreated: '',
        id: -1,
        documentId: id,
        tags: [],
        notes: null
      }
    });

    /* save to backend; on success update store */
    SavedService.saveDocuments(recordsToSave).then(response =>{
      dispatch(clearSelectedResults())
      dispatch(addToSavedDocuments(response));
      savedToast(Constants.DOCUMENTS)
    }).catch(e => handleError(e))
  }

  const saveBibliography = ():void =>{
    /* bulk save --> multiple selection, record save --> single selection */
    const unSavedRecordIds: number[] = savedOrUnsavedIds(selectedRecords, savedState.bibliographies, Constants.SAVED_BIBLIOGRAPHY_KEY, true);
    const recordsToSave: TSavedBibliography[] = unSavedRecordIds.map((id: any) => {
      return {
        id: -1,
        biblioId: id
      }
    });

    /* save to backend; on success update store */
    SavedService.saveBibliographies(recordsToSave).then(response =>{
      dispatch(addToSavedBibliographies(response));
      savedToast(Constants.PUBLICATIONS)
    }).catch(e => handleError(e))
  }

  const unSaveBibliography = (): void=> {
    const savedRecordIds: number[] = savedOrUnsavedIds(selectedRecords, savedState.bibliographies, Constants.SAVED_BIBLIOGRAPHY_KEY);
    SavedService.deleteBibliographies(savedRecordIds).then(() =>{
      dispatch(removeFromSavedBibliographies(recordIdsListForRemoval()));
    }).catch(e => handleError(e))
  }

  const unSaveSearch = (): void=> {
    const savedSearchIds: number[] = savedOrUnsavedIds(selectedRecords, savedState.searches, Constants.ID);
    SavedService.deleteSearches(savedSearchIds).then(() =>{
      dispatch(removeFromSavedSearches(recordIdsListForRemoval()));
      dispatch(removeFromSavedHistory(selectedRecords));
    }).catch(e => handleError(e))
  }

  const saveSearch = ():void =>{
    SavedService.saveSearches(selectedRecords).then(response =>{
      dispatch(addToSavedSearch(response[0]));
      dispatch(updateSearchResult(response[0]));
      dispatch(addToSavedHistory(response));
      savedToast(Constants.SEARCH)
    }).catch(e => handleError(e))
  }

  const handleClick = (fixedState: string | null = null):void => {
    if (!userState.loggedIn) {
      setPendingLogin(true);
      setLoginPromptModal(true);
    } else {
      switch (cardContext) {
        case Constants.DOCUMENT:
          if (fixedState === Constants.SAVE) {
            saveDocuments();
          } else if (fixedState === Constants.UNSAVE) {
            confirmUnsaveDocument();
          } else {
            if (!saved) {
              saveDocuments()
            } else {
              confirmUnsaveDocument();
            }
          }
          break;

        case Constants.BIBLIOGRAPHY:
          if (fixedState === Constants.SAVE) {
            saveBibliography();
          } else if (fixedState === Constants.UNSAVE) {
            unSaveBibliography();
          } else {
            if (!saved) {
              saveBibliography()
            } else {
              unSaveBibliography();
            }
          }
          break;

        case Constants.SEARCH:
          if (fixedState === Constants.SAVE) {
            saveSearch();
          } else if (fixedState === Constants.UNSAVE) {
            unSaveSearch();
          } else {
            if (!saved) {
              saveSearch()
            } else {
              unSaveSearch();
            }
          }
          break;
      }
    }
  }

  const stateButton = ():JSX.Element => {
    return (
      <OverlayTrigger overlay={
          <Tooltip className={selectedRecords.length ? 'hidden' : ''}>{Constants.TOOLTIP_PAGE_PAGE_TOOLS}</Tooltip>
        }>
        <span className={'save-btn text-nowrap w-100 '}>
          <Button
            disabled={(selectedRecords.length === 0)}
            className={'btn rounded-1 m-0 w-100 text-smaller bg-transparent text-dark border border-dark'}
            onClick={()=>handleClick()}
          >
            {!saved &&
              <span>
                <i className={'bi bi-bookmark me-2'}/>
                Add&nbsp;to&nbsp;Library
              </span>}

            {saved &&
              <span>
                <i className={'bi bi-bookmark-x me-1'}/>
                Remove&nbsp;from&nbsp;Library
              </span>}
          </Button>
        </span>
        </OverlayTrigger>
      )
  }

  const discreteButtons = ():JSX.Element => {
    return (
      <>
          <Button
            disabled={(selectedRecords.length === 0)}
            className={'btn rounded-1 m-0 text-smaller bg-transparent text-dark border border-dark'}
            onClick={()=>handleClick(Constants.SAVE)}
          >
            <i className={'bi bi-bookmark me-2'}/>
            Add&nbsp;to&nbsp;Library
          </Button>

           <Button
             disabled={(selectedRecords.length === 0)}
             className={'btn rounded-1 m-0 text-smaller bg-transparent text-dark border border-dark'}
             onClick={()=>handleClick(Constants.UNSAVE)}
           >
            <i className={'bi bi-bookmark-x me-1'}/>
            Remove&nbsp;from&nbsp;Library
          </Button>
      </>
    )
  }

  return (<>
    {(context===Constants.TOOLBAR) &&
      <span className={'save-btn text-nowrap '}>
        {discreteButtons()}
      </span>}

    {((context === Constants.CARD) ||
      (context===Constants.DOCUMENT)) &&
      <span className={'save-btn text-nowrap '}>
        {stateButton()}
      </span>}

    {loginPromptModal && <LoginPromptModal
      title={'Save ' + Utils.toTitleCase(cardContext)}
      show={loginPromptModal}
      onHide={()=>setLoginPromptModal(false)}/>}

    {<ConfirmModal
      show={confirmModalOpen}
      onHide={()=>setConfirmModalOpen(false)}
      callback={()=>{
        setConfirmModalOpen(false);
        unSaveDocuments();
      }}
      title={'Are you sure?'}
      confirmBtnText={'Yes, unsave and delete tags/notes'}
      content={<div>
        <p>Clearing this bookmark will also delete any tag or notes you have for this document.</p>
        <p>Do you want to continue?</p>
      </div>}
    />}

  </>)
}

export default Save;
