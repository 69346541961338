import './styles.scss';
import React, {Dispatch, useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import {TFilter, TFilterOption, TFilterPivot} from "../../../types/search";
import {IPageContext, PageContext} from "../../../types/app";

/* Bootstrap */
import {Accordion, Button,  Offcanvas} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {ISearchDate, ISearchState} from "../../../types/redux/search";
import {RootState} from "../../../redux";
import {IResultsState} from "../../../types/redux/result";
import {
  addFilters,
  clearFilters,
  setAppliedFilters,
  setSearchDates, setSelectedFilters
} from "../../../redux/slices/search-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {ITagState, TSavedDocumentTag} from "../../../types/redux/saved";
import {setResultsFacetFields} from "../../../redux/slices/result-slice";

/* api */
import SearchService from "../../../api/search";

/* Components */
import FiltersFilter from "../filters-filter";
import Spinner from "../../util/spinner";

/* hooks */
import {useQuery} from "react-query";
import useFilterOptions, {IUseFilterOptions} from "../hooks/useFilterOptions";

interface IProps {
  label: string;
}

/* todo: next - break out slideout for base filter component */

const FiltersSlideout = (props: IProps):JSX.Element => {
  const {
    label
  } = props;

  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const tags:  TSavedDocumentTag[] = useSelector<RootState, ITagState>(state => state.tags).tags;
  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const pageContext: string = useContext<IPageContext>(PageContext).context;

  /* state */
  const [availableFilters, setAvailableFilters] = useState<TFilter[]>([]);
  const [activeKeys, setActiveKeys] = useState<any>([Constants.DISPLAY, Constants.INDUSTRY]);
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const filterOptions: IUseFilterOptions = useFilterOptions();

  const {
    data: facetQueryData,
    isSuccess: isFacetQuerySuccess,
    refetch: facetQueryRefetch,
  } = useQuery<any, Error>(
    [JSON.stringify(searchState) + "_facets"],
    async ({signal}) => {
      switch (searchState.filters_key) {
        case Constants.DOCUMENTS:
          return await SearchService.solrDocumentQuery(searchState, undefined, true)

        case Constants.BIBLIOGRAPHY:
          return await SearchService.solrBibliographyQuery(searchState, signal, true)
      }
    },
    {
      staleTime: Infinity,
      enabled: false
    }
  );

  const {
    data: noDateQueryData,
    isSuccess: isNoDateQuerySuccess,
    refetch: noDateQueryRefetch,
  } = useQuery<any, Error>(
    [JSON.stringify(searchState) + "_noDate"],
    async ({signal}) => {
      switch (searchState.filters_key) {
        case Constants.DOCUMENTS:
          return await SearchService.solrDocumentQuery(searchState, undefined, false, true)

        case Constants.BIBLIOGRAPHY:
          return await SearchService.solrBibliographyQuery(searchState, signal, false)
      }
    },
    {
      staleTime: Infinity,
      enabled: false
    }
  );

  useEffect(()=>{
    if (isNoDateQuerySuccess) {
      const _availableFilters: TFilter[] = JSON.parse(JSON.stringify(availableFilters));
      let flattenedFilters: TFilterOption[] = [];
      _availableFilters.forEach(f=>{
        if (f.options.length > 0) {
          flattenedFilters = flattenedFilters.concat(f.options)
        } else if (f.pivot_options.length > 0){
          f.pivot_options.forEach(po=>{
            flattenedFilters = flattenedFilters.concat(po.options )
          })
        }
      })

      const noDateIndex: number = flattenedFilters.findIndex(f=>f.id === 'no_date');
      if (noDateIndex !== -1) {
        const filter: TFilterOption = flattenedFilters[noDateIndex];
        filter.count = noDateQueryData.response.numFound;
      }
      setAvailableFilters(_availableFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNoDateQuerySuccess]);

  useEffect(()=> {
    if (isFacetQuerySuccess) {
      if (facetQueryData && facetQueryData.facet_counts) {
        dispatch(setResultsFacetFields({data:facetQueryData.facet_counts}));
      }

      if (facetQueryData && facetQueryData.facet_counts) {
        const cln: any = JSON.parse(JSON.stringify(facetQueryData.facet_counts));

        if (facetQueryData.facet_counts.facet_ranges.dddate) {
         const dateCounts: any = facetQueryData.facet_counts.facet_ranges.dddate.counts;
         let date: Date;
         let thresholdDate: Date = new Date(Constants.DATE_1950);
         let belowThresholdCount: number = 0;
         let lastEntryPos: number = 0;
         for (let i = 0; i < dateCounts.length; i++) {
           date = new Date(dateCounts[i]);
           if (date < thresholdDate) {
             belowThresholdCount += dateCounts[i + 1]
             lastEntryPos = i + 1;
           }
           i++;
         }

         const filterDateRanges: (string | number)[] = [...dateCounts].slice(lastEntryPos + 1, dateCounts.length);
         filterDateRanges.unshift(belowThresholdCount);
         filterDateRanges.unshift(Constants.DATE_LESS_THAN_1950);
         cln.facet_ranges.dddate.counts = filterDateRanges;
       }

       dispatch(setResultsFacetFields({data:cln}));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFacetQuerySuccess, facetQueryData, dispatch])

  useEffect(()=> {
    if ((pageContext === Constants.MY_LIBRARY)) {
      return;
    }

    setLoading(true)
    setAvailableFilters([]);
    facetQueryRefetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.db_set, searchState.industry, searchState.query]);

  /* parse out filters from response data in a format the UI can consume */
  useEffect(()=>{
    if ((!resultsState.facet_fields && (searchState.filters_key !== Constants.TAGS))) return;

    const filters: TFilter[] = [];
    // @ts-ignore
    searchState.filters_db[searchState.filters_key].forEach((filterEntry: TFilter) => {
      const filter: TFilter = {...filterEntry}

      if (filter.facet_pivot) {
        filter.pivot_options = filterOptions.facetPivotOptions(filter, resultsState.facet_pivot[filter.facet_pivot]);
      } else if (filter.facet_ranges ) {
        filter.pivot_options = filterOptions.facetFieldsOptions(filter, resultsState.facet_ranges[filter.facet_ranges].counts) as TFilterPivot[];
      } else if (filter.facet_field) {
        filter.options = filterOptions.facetFieldsOptions(filter, resultsState.facet_fields[filter.facet_field]) as TFilterOption[];
      } else if (searchState.filters_key === Constants.TAGS) {
        filter.options = filterOptions.filterTagOptions(filter, tags);
      } else {
        filter.options = filterOptions.filterDBOptions(filter);
      }

      if ((filter.id === Constants.INDUSTRY.toLowerCase()) && (searchState.db_set === Constants.DOCUMENTS)){
        // populate industry field for all-industry collections filters
        filter.pivot_options.forEach(f=>{
          const industry: string = f.value.toLowerCase();
          f.options.forEach((fo: any) => {
            fo.industry = industry
          })
        })
      }

      /* add filter to available filters */
      if ((filter.options && (filter.options.length > 0)) || (filter.pivot_options && (filter.pivot_options.length > 0))) {
        if (searchState.industry === Constants.ALL_INDUSTRIES) {
          if (filter.id !== Constants.COLLECTION) {
            filters.push(filter)
          }
        } else {
          if (filter.id !== Constants.INDUSTRY) {
            filters.push(filter)
          }
        }
      }
    });
    setAvailableFilters(filters);
    noDateQueryRefetch();
    setLoading(false);

    /* This is the case where filters need to be loaded before a search. Eg. Page refresh or Shared link */
    if (searchState.selected_filters_ids.length !== searchState.applied_filters.length) {
      handleApply(filters);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsState.facet_fields, resultsState.facet_pivot, resultsState.facet_ranges, tags])

  const handleExpand = (filterEntry: TFilter, index: number, value: boolean): void =>{
    const updatedFilters: TFilter[] = [...availableFilters];
    const filter: TFilter = {...filterEntry}
    filter.expanded = value;
    updatedFilters[index] = filter;
    setAvailableFilters(updatedFilters);
  }

  const handleToggleItem = (key: string):void => {
    const index = activeKeys.indexOf(key);
    if (index > -1) {
      activeKeys.splice(index, 1);
      setActiveKeys([...activeKeys]);
    } else {
      setActiveKeys(activeKeys.concat(key));
    }
  }

  const handleReset = (): void => {
    dispatch(clearFilters())
  }

  /* todo: next - display filter need to be preappended */
 /* const addRemoveSelectedOptions = (mutations: ISelectedMutations): void => {
    const selectedFiltersCln: TFilterOption[] = [...selectedFilters];
    mutations.add.forEach((filterOption: TFilterOption) =>{
      const index: number = selectedFilters.findIndex(f=>f.id === filterOption.id);
      if (index === -1) {
        if (filterOption.add === Constants.PREPEND) {
          selectedFiltersCln.unshift({...filterOption});
        } else {
          selectedFiltersCln.push({...filterOption});
        }
      }
    })

    mutations.remove.forEach((filterOption: TFilterOption) =>{
      const index: number = selectedFiltersCln.findIndex(f=>f.id === filterOption.id);
      if (index !== -1) {
        selectedFiltersCln.splice(index, 1);
      }
    })
    setSelectedFilters(selectedFiltersCln);
  }*/

  const handleApply = (filters: TFilter[]): void => {
    setShow(false);

    const filtersToAdd: TFilterOption[] = [];

    /* make filters enumerable */
    let flattenedFilters: any[] = [];
    filters.forEach(f=>{
      if (f.options.length > 0) {
        flattenedFilters = flattenedFilters.concat(f.options)
      } else if (f.pivot_options.length > 0){
        f.pivot_options.forEach(po=>{
          flattenedFilters = flattenedFilters.concat(po.options)
        })
      }
    })

    /* add filters to applied filters in search state */
    searchState.selected_filters_ids.forEach(id=> {
      const filterOption: TFilterOption = flattenedFilters.find(fo => fo.id === id)
      if (filterOption) {
        filtersToAdd.push({...filterOption})
      } else {
        console.error('ERROR: unable to apply unavailable filter')
      }
    });

    const selectedFiltersCln: TFilterOption[] = [...flattenedFilters]
    const dateOptionsSplit: any = selectedFiltersCln.reduce((acc: any, cur: TFilterOption) => {
      if (cur.fq_group === Constants.DATE) {
        acc.dates = [...acc.dates || [], cur];
      } else {
        acc.other = [...acc.other || [], cur];
      }
      return acc;
    }, {});

    if (dateOptionsSplit.dates) {
      const searchDates: ISearchDate[] = dateOptionsSplit.dates.map((fo:TFilterOption) => {
        const startDate: Date = new Date(fo.value);
        const yearsRange: number = (fo.id.indexOf(Constants.SELECT_ALL) !== -1) ? 10 : 1;
        const endDate: Date = new Date(startDate.getFullYear() + yearsRange, startDate.getMonth(), startDate.getDate() )
        return {
          field: fo.field,
          from: startDate.toLocaleDateString(),
          to: endDate.toLocaleDateString()
        }
      })
      dispatch(setSearchDates(searchDates))
    } else {
      dispatch(setSearchDates([]));
    }

    if (dateOptionsSplit.other) {
      dispatch(addFilters(dateOptionsSplit.other));
    }
    dispatch(setAppliedFilters(filtersToAdd))
  }

  const closeSlideout = ()=>{
    dispatch(setSelectedFilters(searchState.applied_filters.map(f=>f.id)));
    setShow(false)
  }
  return (
    <div className={'filters-slideout'}>
      <Button
        id={'button-filters'}
        className={'px-3'}
        variant={'light'}
        onClick={() => setShow(true)}
      >
        <i className={'bi bi-filter-left text-larger me-1'}></i>
        <span className={'text-small align-middle'}>{label}</span>
      </Button>

      <Offcanvas
        className={'slideout'}
        show={show}
        onHide={() => closeSlideout()}>
        <Offcanvas.Header closeButton className={'pb-0 ps-4'}>
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className={'px-0'}>
          <div className={'d-flex justify-content-between border-bottom pb-3 px-4'}>

            {/* Reset */}
            <Button className={'btn-link link-underline-none p-0 text-xnormal'} onClick={handleReset}>
              Reset
            </Button>

            {/* Apply */}
            <Button
              onClick = {()=>handleApply(availableFilters)}
              className={'text-small px-4'}
            >
              Apply
            </Button>
          </div>

          <div className={'filters position-absolute overflow-auto w-100'}>
            <Accordion className={'filters-checkboxes'} flush alwaysOpen activeKey={activeKeys}>
              {loading &&
                <div className={'mt-4 text-center'}>
                  <Spinner size={Constants.MEDIUM}/>
                  <div className={'text-small p-4'}>
                    <p className={'p-0 m-0'} >Filters load after query results are available.</p>
                    <p className={'p-0 m-0 mt-2'} >This may take a moment... </p>
                  </div>
                </div>}

              {/* for all filters */}
              {!loading && availableFilters.map((filter: TFilter, filterIndex: number) => {

               /* if (filter.id === 'industry') {
                  console.log('FiltersFilter filter.id ' + filter.id)
                }*/
                return <FiltersFilter
                  key={filter.id + filterIndex}
                  opened={activeKeys.indexOf(filter.id) !== -1}
                  filter={{...filter}}
                  filterIndex={filterIndex}
                  handleToggleItem={handleToggleItem}
                  handleExpand={handleExpand}/>
                })
              }
            </Accordion>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}
export default FiltersSlideout;
