import './styles.scss';
import React, {Dispatch, useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../constants";
import {PageContext} from "../../types/app";
import Utils from "../../utils";

/* Bootstrap */
import {Col, Container, Row} from "react-bootstrap";

/* Redux */
import {ISearchState} from "../../types/redux/search";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {addCrumb} from "../../redux/slices/breadcrumb-slice";

/* Components */
import ViewScrollContainer from "../../components/view/view-scroll-container";
import NewsView from "./components/news";

/* Hooks */
import ContentPage from "./components/page";
import IndustryNavbar from "../../components/industry-navbar";
import {Category} from "../../api/payload/payload-types";
import {setContentCategory} from "../../redux/slices/content-slice";
import {IContentState} from "../../types/redux/content";
import {useLocation} from "react-router-dom";
import {HistoryContext} from "../../components/util/history";

interface IProps {
  context: string
}

const Content = (props: IProps):JSX.Element => {
  const {
    context
  } = props;

  const searchState: ISearchState = useSelector<RootState, ISearchState>((state: RootState):any => state.search);
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const [hideSubHeader, setHideSubHeader] = useState<boolean>(false)
  const { industry, section } = Utils.parseIndustrySection();
  const contentState: IContentState = useSelector<RootState, IContentState>((state: RootState):any => state.content);
  const { history, setHistory } = useContext(HistoryContext);
  const { pathname } = useLocation();

  useEffect(()=>{
    /* router is using history.replace so new url is not being picked up by App */
    const _history: string[] = [...history];
    _history.push(pathname);
    setHistory(_history)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(()=> {
    let _heading: string = Utils.toTitleCase(context);
    let level: number = 2;

    const prevPathname: string = history[history.length - 1];

    if (context === Constants.CATEGORY) { /* verse context === Constants.NEWS - see routes in App.tsx */
      _heading = Utils.toTitleCase(section);
      _heading = Utils.capitalizeWord(_heading, 'idl'); // special case - capitalize IDL in heading
      level = (pathname.indexOf(prevPathname) !== -1) ? 1 : 2;
    }

    if (level === 1) {
      dispatch(addCrumb({
        label: _heading,
        href: '/' + _heading.toLowerCase().replaceAll(' ', '-') + '/',
        level: 1,
        active: true
      }));
    }

    if (context === Constants.NEWS) {
      setHideSubHeader(Utils.parseNewsArticleId()!== null);
    }

    const categoryData: Category | undefined = contentState.categories.find(c=>section === c.title?.replace(' ', '-').toLowerCase());
    if (section && categoryData) {
      dispatch(setContentCategory(categoryData));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchState.industry, context, window.location.pathname])

  return (
    <div className={'content-view view'}>
      <PageContext.Provider value={{context: Constants.CONTENT}}>
        <ViewScrollContainer>
          {!hideSubHeader &&
            <Container className={'subheader-container'}>
              {(industry !== Constants.GLOBAL) && <IndustryNavbar  />}
            </Container>}

          <Container className={"page-container px-0 px-lg-4" + (hideSubHeader? " no-header" : "")}>
            <Row>
              <Col lg={12}>
                {context === Constants.NEWS ? <NewsView/> : <ContentPage />}
              </Col>
            </Row>
          </Container>
        </ViewScrollContainer>
      </PageContext.Provider>
    </div>
  )
}

export default Content;
