import {TFilterOption} from "../../types/search";

export const filters = (filterOptions: TFilterOption[]): string => {
  let fqString: string = '';
  let industries: string[] = [];
  const fqGroups:  { [key: string]: TFilterOption[]; } = {}
  filterOptions.forEach(filter=>{
    const filterCln: TFilterOption = {...filter}
    if (filterCln.field==='collection_facet') {
      if (filterCln.value.indexOf('"') === -1) {
        filterCln.value = "\""+filterCln.value+"\"";
      }

      if (filterCln.industry){
        industries.push(filterCln.industry)
        // all industry collection filter corresponding industry
        //fqString = '&fq=industrycode:' + filterCln.industry;
      }
    }

    if (fqGroups[filter.fq_group]) {
      fqGroups[filter.fq_group].push(filterCln);
    } else {
      fqGroups[filter.fq_group] = [filterCln]
    }
  })

  if (industries.length > 0) {
    let fqIndustries: string = '';
    for (let i=0; i< industries.length; i++) {
      fqIndustries += `(industrycode:${industries[i]})`;
      if (i < industries.length - 1) {
        fqIndustries += ' OR '
      }
    }
    fqString = '&fq=' + fqIndustries;
  }

  for (let fqGroup in fqGroups) {
    let filterString: string = '';
    for (let i=0; i<fqGroups[fqGroup].length; i++) {
      const filterOption: TFilterOption = fqGroups[fqGroup][i];
      let filterAddition: string = '';
      if (filterOption.value === '*') {
        filterAddition = filterOption.field + ':' + filterOption.value;
      } else {
        if (filterOption.value.indexOf('"') === -1) {
          filterOption.value = '"' + filterOption.value + '"';
        }
        filterAddition = ( ((i > 0) ? ' OR ' : '') + (filterOption.field ? '(' + filterOption.field + ':' : '')  + filterOption.value + (filterOption.field ? ')' : ''));
      }
      filterString += filterAddition
    }

    fqString += '&fq=' + encodeURIComponent(filterString)
  }

  return fqString;
}
