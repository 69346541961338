import './styles.scss';
import React, {Dispatch, useContext} from "react";

/* types, constants, utils */
import * as Constants from "../../../constants";

/* components*/
import ViewerMetadata from "../viewer-metadata";
import ViewerCite from "../viewer-cite";
import ViewerTagsNotes from "../viewer-save";
import ViewerDownload from "../viewer-download";
import ViewerShare from "../viewer-share";
import {IViewerState} from "../../../types/redux/viewer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import ViewerRecords from "../viewer-records";
import ReportIssue from "../../report-issue";
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {IViewerRecordContext, ViewerRecordContext} from "../../../types/document";
import {AnyAction} from "@reduxjs/toolkit";
import {setViewPublicRecord} from "../../../redux/slices/viewer-slice";

interface IProps {
  activeTool: string;
  setActiveTool(value:string):void;
}

const ViewerPanel = (props: IProps):JSX.Element => {
  const {
    activeTool,
    setActiveTool
  } = props;

  const dispatch:Dispatch<AnyAction> = useDispatch();
  const viewingRecordContext: IViewerRecordContext = useContext<IViewerRecordContext>(ViewerRecordContext);
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);

  const toolPanel = ():JSX.Element => {
    let tool: JSX.Element;
    switch (activeTool) {
      case Constants.METADATA:
        tool = <ViewerMetadata/>
        break;

      case Constants.SEARCH_RESULTS:
      case Constants.BROWSE:
      case Constants.BATES:
      case Constants.MORE_LIKE_THIS:
      case Constants.POSSIBLE_DUPLICATES:
        tool = <ViewerRecords
          activeTool={activeTool}
          handleToolSelect={setActiveTool}
        />
        break;

      case Constants.CITE:
        tool = <ViewerCite/>
        break;

      case Constants.TAGS_NOTES:
        tool = <ViewerTagsNotes/>
        break;

      case Constants.DOWNLOAD:
        tool = <ViewerDownload/>
        break;

      case Constants.SHARE:
        tool = <ViewerShare/>
        break;

      case Constants.REPORT_ISSUES:
        return tool = <div className={'mt-4'}>
          <ReportIssue defaultSelection={''} />
        </div>;

      default:
        tool = <></>;
    }
    return tool;
  }

  return (
    <div className={'viewer-panel bg-white h-100 border-end ' + (viewerState.panelCollapsed ? ' hidden': '')}>
      {viewingRecordContext.publicRecord &&
        <div className={'border-bottom'}>
          <ToggleButtonGroup
            type="checkbox"
            defaultValue={[1]}
            className={'public-btns m-2'}
          >
            <ToggleButton
              id={'non-public-toggle'}
              value={2}
              className={'ms-1 text-small rounded' + (!viewerState.viewPublicRecord ? ' active':'')}
              onMouseDown={()=> dispatch(setViewPublicRecord(false))}
            >
              Restricted Version
            </ToggleButton>

            <ToggleButton
              id={'public-toggle'}
              value={1}
              className={'text-small rounded' + (viewerState.viewPublicRecord ? ' active':'')}
              onMouseDown={()=> dispatch(setViewPublicRecord(true))}
            >
              Public Version
            </ToggleButton>
          </ToggleButtonGroup>
      </div>}
      {toolPanel()}
    </div>
  )
}
export default ViewerPanel;
