import {TQueryItem} from "../../constants";
import {IFilterDB, TFilterOption} from "../search";

export interface ISearchDate {
  field: string;
  from: string | null;
  to: string | null;
}

export interface ISearchState {
  industry: string;
  db_set: string | null;
  query: TQueryItem[];
  dates: ISearchDate[];
  results_per_page: number;
  current_page: number;
  sort: string;
  selected_filters_ids: string[];
  applied_filters: TFilterOption[];
  filters_db: IFilterDB;
  filters_key: string;
  recent_query: boolean; // save in search history
}

export type ISearchEntry = {
  id: number;
  dateCreated: string;
  searchDateTime: string;
  count: number;
  url: string;
}

export const UpdateSearchAction: string = "update-saved-action";
